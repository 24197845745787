import { Request } from '@/firebase/request'

const get = (context, props) => {
  const request = new Request(context, props)
  return request.get()
}

const list = (context, props) => {
  const request = new Request(context, props)
  return request.list()
}

const set = (context, props) => {
  const request = new Request(context, props)
  return request.set()
}

const update = (context, props) => {
  const request = new Request(context, props)
  return request.update()
}

const del = (context, props) => {
  const request = new Request(context, props)
  return request.delete()
}

export default {
  get,
  set,
  list,
  update,
  del,
}
