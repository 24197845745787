<template>
  <div>
    <div
      v-if="loading"
      :class="$style.loader"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <VCard
      v-else
      class="mx-auto mt-8"
      max-width="1200px"
      outlined
    >
      <VCardTitle>{{ $t('calendar.pageTitle') }}</VCardTitle>
      <VCardText>
        <EventsProvider
          v-if="canAccess && user"
          :user="user"
          :organizationId="organizationId"
          :date="date"
        >
          <div slot-scope="{ data, loading }">
            <div v-if="loading">{{$t('global.loading')}}</div>
            <div v-else :class="styleGrid">
              <div v-if="!isNotCustomer">
                <ContractorsProvider>
                  <div slot-scope="{ items, loading }">
                    <Select v-model="contractorId" :items="items" :loading="loading" />
                  </div>
                </ContractorsProvider>

                <DatePickerMultiple
                  v-if="contractorId"
                  :events="data"
                  :contractorId="contractorId"
                  :key="contractorId"
                />
              </div>
              <Calendar :events="data" @change="hasChanged" />
            </div>
          </div>
        </EventsProvider>

        <div v-else>
          <p>{{$t('calendar.accessDenied')}}</p>

          <VBtn
            :to="{name: 'organization'}"
          >
            {{ $t('calendar.configEntity') }}
          </VBtn>
        </div>
      </VCardText>
    </VCard>
  </div>
</template>

<script>

import {
  VCard,
  VCardTitle,
  VCardText,
  VProgressCircular,
} from 'vuetify/lib'
import { Calendar } from '@/modules/Calendar'
import EventsProvider from '@/modules/Calendar/EventsProvider'
import ContractorsProvider from '@/components/Contractor/ContractorsProvider'
import DatePickerMultiple from '@/modules/Calendar/components/DatePickerMultiple.vue'
import Select from '@/components/Contractor/Select.vue'
import {logEvent} from '@/utils/logEvent'

export default {
  name: 'CalendardView',
  components: {
    DatePickerMultiple,
    EventsProvider,
    ContractorsProvider,
    Calendar,
    VProgressCircular,
    VCard,
    VCardTitle,
    VCardText,
    Select,
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - Calendar',
  },
  data() {
    return {
      date: '',
      contractorId: '', // 'VWvMLckszXSoT5SSN6EaAlaDctH3', // toriyama@tori.com
    }
  },
  computed: {
    loading() {
      return this.$store.getters['requests/loading']({
        name: 'currentUser-get',
      })
    },
    user() {
      return this.$user.get()
    },
    styleGrid() {
      return (!this.isNotCustomer) ? this.$style.grid : ''
    },
    organizationId() {
      return this.user?.organization?.docId
    },
    isNotCustomer() {
      return !this.user?.role?.customer
    },
    canAccess() {
      const isContractor = this.user?.role?.contractor
      const isCustomer = this.user?.role?.customer && this.organizationId
      return isCustomer || isContractor
    },
  },
  created() {
    // Record custom event to analytics "calendarPage"
    logEvent('calendarPage')
  },
  methods: {
    hasChanged(val) {
      this.date = val.start.date
    },
  },
}
</script>

<style lang="scss" module>
.grid {
  display: grid;
  grid-template-columns:  290px auto;
  grid-gap: 20px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.loader {
  display: flex;
  justify-content:center;
  align-items:center;
  height: 50vh;
}

</style>
