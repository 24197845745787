import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/messaging'
import 'firebase/analytics'
import 'firebase/performance'

const {
  VUE_APP_FIREBASE_API_KEY,
  VUE_APP_FIREBASE_AUTH_DOMAIN,
  VUE_APP_FIREBASE_DATABASE_URL,
  VUE_APP_FIREBASE_PROJECT_ID,
  VUE_APP_FIREBASE_STORAGE_BUCKET,
  VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  VUE_APP_FIREBASE_APP_ID,
  VUE_APP_FIREBASE_MEASUREMENT_ID,
} = process.env

const config = {
  apiKey: VUE_APP_FIREBASE_API_KEY,
  authDomain: VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: VUE_APP_FIREBASE_DATABASE_URL,
  projectId: VUE_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  storageBucket: VUE_APP_FIREBASE_STORAGE_BUCKET,
  appId: VUE_APP_FIREBASE_APP_ID,
  measurementId: VUE_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(config)

const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()
const perf = firebase.performance()
let analytics // eslint-disable-line

if (process.env.VUE_APP_MODE === 'production') {
  analytics = firebase.analytics()
}

let messaging // eslint-disable-line
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
} else {
  messaging = null
}
// const messaging = firebase.messaging()

// // Add the public key generated from the console here.
// const PUBLICVAPIKEY = 'BLGpoYO6J1le_izGcGBC18sPYuwnhX5OLj6h7GThsjJhFgX9TTkVZ75KtZdKmAlRHFr3vF8x7AHunuqhWPV3ww0'
// messaging.usePublicVapidKey(PUBLICVAPIKEY)

// auth.onAuthStateChanged(async (u) => {
//   // if connected
//   if (u !== null && messaging) {
//     // request notification token
//     messaging.requestPermission()
//       .then(() => {
//         console.log('Notification permission asked')
//         return messaging.getToken()
//       })
//       .then(async (token) => {
//         if (token) {
//           console.log('Notification permission granted', token)

//           const payload = {
//             token,
//             user: {
//               docId: u.uid,
//               email: u.email,
//             },
//             navigator: {
//               userAgent: navigator.userAgent,
//               language: navigator.language,
//             },
//             date: new Date(),
//           }

//           console.log('Notification payload', payload)

//           await db.collection('notificationsTokens').doc(payload.token).set(payload, { merge: true })
//         } else {
//           // Show permission request.
//           // console.log('No Instance ID token available. Request permission to generate one.')
//         }
//       })
//     // .then((payload) => db.collection('notificationsTokens').doc(payload.token).set(payload, { merge: true }))
//       .catch((error) => console.log('Notification permission denied', error.message))

//     // Callback fired if Instance ID token is updated.
//     messaging.onTokenRefresh(() => {
//       console.log('Notification permission updated')
//     })

//     // notification showUp if tab is active
//     // messaging.onMessage((payload) => {
//     //   console.log('onMessage: ', payload)
//     // })
//   }
// })

// const functions = firebase.functions()
// https://firebase.google.com/docs/functions/locations#web
// needed for use function onCall and pass CORS
const functions = firebase.app().functions('europe-west1')
// Localization can be set via the Firebase Console or dynamically
// by calling the corresponding client API before triggering the email action.
// For example, using JavaScript:
firebase.auth().languageCode = 'fr'

// if (window.location.hostname === 'localhost') {
//   // use caching DATA
//   // https://firebase.google.com/docs/firestore/manage-data/enable-offline
//   // disable network
//   // Note that the Firebase Web SDK must connect to the WebChannel port
//   db.settings({
//     host: process.env.VUE_APP_FIRESTORE_EMULATOR_HOST,
//     ssl: false,
//   })
// }

if (process.env.VUE_APP_MODE === 'development') {
  window.firebase = {
    auth,
    db,
    functions,
    storage,
    messaging,
    perf,
    analytics,
  }
}

export {
  auth, db, functions, storage, messaging, analytics,
}
