<template>
  <v-switch
    v-model="dark"
    :ripple=false
    dense
    @change="Onchange"
  ></v-switch>
</template>

<script>
import { VSwitch } from 'vuetify/lib'
import {logEvent} from '@/utils/logEvent'

export default {
  name: 'ThemeSwitch',
  components: {
    VSwitch,
  },
  data() {
    return {
      dark: this.$vuetify.theme.dark,
    }
  },
  methods: {
    Onchange() {
      // Record custom event to analytics "themeSwitch"
      logEvent('themeSwitch', {
        is_dark: this.dark,
      })

      this.$vuetify.theme.dark = this.dark
      window.localStorage.setItem('dark', this.dark)
    },
  },
}
</script>

<style lang="scss" module>

</style>
