<template>
  <div :class="$style.wrapper">
    <Login />
  </div>
</template>

<script>
import { Login } from '@/modules/Login'
import {logEvent} from '@/utils/logEvent'

export default {
  name: 'LoginView',
  components: {
    Login,
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - Login',
  },
  created() {
    // Record custom event to analytics "loginPage"
    logEvent('loginPage')
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
