<template>
  <div :class="$style.wrapper">
    <!-- <v-navigation-drawer
    app
    right
    :permanent='$vuetify.breakpoint.smAndUp'
    v-if="development && debug"
  >
    <Debug v-model="drawerDebug"/>
  </v-navigation-drawer> -->

    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      :class="drawer"
    >
      <v-list dense>
        <v-list-item
          link
          v-for="item in items"
          :key="item.name"
          :to="{name: item.routeName}"
          exact>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
                {{ $t(item.label) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div :class="$style.theme">
         <div>Theme</div>
         <ThemeSwitch></ThemeSwitch>
      </div>

    </v-navigation-drawer>

    <AppBar v-if="header" v-model="drawer" />

    <VMain :class='$style.content'>
      <VAlert
        v-model="motificationShow"
        dismissible
        close-icon="fa-times"
        color="white"
        light
        icon="fa fa-bell"
        dense
      >
        {{ motification.title }}
        {{ motification.body }}
        <img :src=motification.icon alt="" />

      </VAlert>

      <slot/>
    </VMain>
  </div>
</template>

<script>
import { messaging /* , functions */ } from '@/firebase'

// import Debug from '@/components/Debug'
import AppBar from '@/components/AppBar'
import ThemeSwitch from '@/components/ThemeSwitch'
import {
  VMain,
  VNavigationDrawer,
  VList,
  VListItem,
  VListItemContent,
  VListItemTitle,
  VListItemAction,
  VIcon,
  VAlert,
} from 'vuetify/lib'
import userMixin from '@/components/mixins'
import { navigation } from './navigation'

export default {
  name: 'AppShell',
  components: {
    // Debug,
    AppBar,
    ThemeSwitch,
    VMain,
    VNavigationDrawer,
    VList,
    VListItem,
    VListItemContent,
    VListItemTitle,
    VListItemAction,
    VIcon,
    VAlert,
    // VCard,
    // VCardText,
  },
  mixins: [userMixin],
  data: () => ({
    navigation,
    drawer: false,
    drawerDebug: false,
    motificationShow: false,
    motification: {}, // title - icon - body
  }),
  computed: {
    items() {
      return navigation(this.role)
    },
    debug() {
      return this.$route.query.debug
    },
    development() {
      return (process.env.VUE_APP_MODE === 'development')
    },
    header() {
      return this.$route.meta.header !== false
    },
  },
  created() {
    messaging.onMessage((payload) => {
      // console.log('🐙🐙 onMessage: ', payload)
      this.motification = payload.data
      this.motificationShow = true
    })
  },
}
</script>

<style lang="scss" module>
.content {
  margin: 0 20px
}
.theme {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<style lang="scss">
.v-navigation-drawer .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>

