<template>
  <VApp>
    <VMain>
      <AppShell>
        <router-view />
      </AppShell>
    </VMain>

    <Footer v-if="footer" :version="lastest_tag" />

    <v-dialog v-model="needRefresh" max-width="290" persistent>
      <v-card>
        <v-card-title class="headline">Mise à jour</v-card-title>

        <v-card-text>
          Une nouvelle version de l'application est disponible. Veillez à rafraichir votre page en
          cliquant sur le bouton.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="updateApp">
            Mise à jour<!-- {{ $t('global.delete') }} -->
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VApp>
</template>

<script>
import { auth, db } from '@/firebase'
import {logEvent} from '@/utils/logEvent'
import AppShell from '@/AppShell.vue'
import Footer from '@/components/Footer/Footer.vue'
// eslint-disable-next-line object-curly-newline
import { VDialog, VCard, VCardTitle, VCardText, VBtn, VSpacer } from 'vuetify/lib'

export default {
  name: 'App',
  components: {
    AppShell,
    Footer,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VBtn,
    VSpacer,
  },
  data: () => ({
    drawer: false,
    appversion: process.env.VUE_APP_GITHUB_SHA,
    lastest_tag: process.env.VUE_APP_LATEST_TAG,
    needRefresh: false,
    newAppVersion: '',
  }),
  computed: {
    footer() {
      return this.$route.meta.footer !== false
    },
    appversionshort() {
      return this.appversion.substring(0, 7)
    },
  },
  created() {
    console.log('appversion:', {
      sha: this.appversion,
      shortsha: this.appversionshort,
      tag: this.lastest_tag,
    })

    // TODO condition production
    if (process.env.VUE_APP_MODE === 'production') {
      this.getVersion()
    }

    auth.onAuthStateChanged(async (u) => {
      if (u !== null) {
        // console.log('🐙 App FETCH auth user')
        // save auth.currentUser to store user
        this.$store.commit('auth/set', u.uid)

        await this.$store.dispatch('entities/get', {
          name: 'currentUser-get',
          docId: u.uid,
          type: 'users',
          force: false,
        })
      }

      // claims role
      // if (u) {
      //   this.claims = auth
      //     .currentUser.getIdTokenResult()
      //     .then((tokenResult) => {
      //       console.log('claims', tokenResult.claims)

      //       return tokenResult
      //     })
      // }
    })
  },
  watch: {
    newAppVersion(value, oldValue) {
      console.log('newAppVersion', { value, oldValue })
      // Record custom event to analytics "app_version"
      logEvent('app_version', { version: value })
    },
  },
  methods: {
    async getVersion() {
      if (process.env.VUE_APP_GETVERSION_DISABLED) {
        return
      }

      try {
        await db
          .collection('application')
          .doc('version')
          .onSnapshot((doc) => {
            console.log('Current data: ', doc.data())
            if (this.appversion !== doc.data().sha) {
              console.log('Version changed')
              this.needRefresh = true
              this.newAppVersion = doc.data().version
            } else {
              console.log('Version not changed')
              this.needRefresh = false
            }
          })
      } catch (e) {
        console.log('getVersion error', e)
      }
    },
    updateApp() {
      // window.location.reload()
      const { protocol, host, pathname } = window.location

      window.location.replace(`${protocol}//${host}${pathname}?v=${this.newAppVersion}`)
    },
  },
}
</script>

<style module>
.wrapper {
  max-width: 90%;
  margin: auto;
}
</style>
