<template>
  <VCard
    class="mx-auto mt-8"
    max-width="1200px"
    outlined
  >
    <VCardTitle>Tous les events, renvoyer un email</VCardTitle>
    <VCardText>
      <EventsListEmail></EventsListEmail>
    </VCardText>
  </VCard>
</template>

<script>
import { VCard, VCardTitle, VCardText } from 'vuetify/lib'
import EventsListEmail from '@/modules/EventsList/EventsListEmail.vue'
import { logEvent } from '@/utils/logEvent'

export default {
  name: 'UsersList',
  components: {
    VCard,
    VCardTitle,
    VCardText,
    EventsListEmail,
  },
  props: {
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - EventsListEmail',
  },
  data: () => ({

  }),
  computed: {
  },
  created() {
    // Record custom event to analytics "eventsListEmailPage"
    logEvent('eventsListEmailPage')
  },
  methods: {
  },
}
</script>

<style lang="scss" module>

</style>
