import Vue from 'vue'

const requests = {
  namespaced: true,
  state: {
    test: false,
  },
  getters: {
    get: (state /* getters, rootState, rootGetters */) => (props) => {
      const { name } = props
      return (state[name]) ? state[name] : false
    },
    loading: (state /* getters, rootState, rootGetters */) => (props) => {
      const { name } = props
      return (state[name]) ? state[name].loading : false
    },
    loaded: (state /* getters, rootState, rootGetters */) => (props) => {
      const { name } = props
      return (state[name]) ? state[name].loaded : false
    },
    result: (state /* getters, rootState, rootGetters */) => (props) => {
      const { name } = props
      return (state[name]) ? state[name].result : []
    },
    timestamp: (state /* getters, rootState, rootGetters */) => (props) => {
      const { name } = props
      return (state[name]) ? state[name].timestamp : ''
    },
  },
  mutations: {
    set(state, props) {
      const {
        name, loading = false, loaded = false, result = [], /* ready = false, */
      } = props

      const data = {
        // ready,
        loaded,
        loading,
        result,
        timestamp: Date.now(),
      }

      Vue.set(state, name, data)
    },
    del(state, props) {
      const { name } = props
      Vue.delete(state, name)
    },
    delAll(state) {
      const stateKeys = Object.keys(state)
      stateKeys.forEach((key) => {
        Vue.delete(state, key)
      })
    },
  },
  actions: {
    set(context, props) {
      context.commit('set', props)
    },
    del(context, props) {
      context.commit('del', props)
    },
    delAll(context) {
      context.commit('delAll')
    },
  },
}

export default requests
