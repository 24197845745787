/* eslint-disable */
import { functions } from '@/firebase'

import tpl from './template' // eslint-disabled-line

const mail = async (context, config) => {
  const { to, subject, mailTitle, html } = config

  const payload = {
    to: [to],
    message: {
      subject,
      text: 'subject',
      html: tpl({
        pageTitle: 'Freeday',
        siteName: 'FreeDay',
        mailTitle,
        mainContent: html,
      }),
    },
  }

  const rand = Math.random() * 1000

  await context.dispatch(
    'entities/set',
    {
      name: `mail-${rand}`,
      type: 'mail',
      data: payload,
    },
    { root: true }
  )
}

const bot = async (config) => {
  const { msg, subject } = config
  // if httpsCallable then to the test on firebase hosting
  const botMessage = await functions.httpsCallable('bot')
  try {
    botMessage({
      msg,
      subject,
    })
  } catch (error) {
    const err = new Error('sendMailerror', error)
    window.newrelic.noticeError(err)
  }
}

const validByClientTrans = (val) => (val ? 'oui' : 'non')

// customer create new Event
// send to contractor
const newEvent = (context, data) => {
  const {
    contractor,
    user,
    organization,
    // start,
    date,
    type,
    name,
    comment,
    validByClient,
  } = data.event

  // console.log('🍋  process.env.VUE_APP_MAIL, ', process.env.VUE_APP_MAIL, typeof process.env.VUE_APP_MAIL)

  // if mail new event
  if (process.env.VUE_APP_MAIL === 'true') {
    // email client
    mail(context, {
      // to: 'julien.hautier@gmail.com, freeday.contact@gmail.com, julienworks@outlook.com, julien.hautier@yahoo.fr',
      to: user.email,
      subject: `Commande de nouvelle formation ${type} pour ${date}`, // Subject line
      mailTitle: 'Nouvelle réservation',
      html: `
      <p>Votre réservation ${type} pour ${date} est en cours de traitement.
      Nous vous enverrons une confirmation par mail.</p>

      <p>Information détaillées :</p>

      <div><strong>Prestataire : ${contractor.fullName}</strong></div>
      <div><strong>Date : ${date}</strong></div>
      <div><strong>Type : ${type}</strong></div>
      <div><strong>Client : ${name}</strong></div>
      <div><strong>Validé par le client : ${validByClientTrans(validByClient)}</strong></div>
      <div><strong>Commentaire : ${comment}</strong></div>
      `,
    })

    // email contractor new event
    mail(context, {
      // to: 'julien.hautier@gmail.com, freeday.contact@gmail.com',
      to: contractor.email,
      subject: "Validation d'une formation requise", // Subject line
      mailTitle: 'Nouvelle réservation',
      html: `
      <p>Vous devez approuver une nouvelle réservation pour ${date}</p>

      <p>Information détaillées :</p>

      <div><strong>Client : ${user.fullName}</strong></div>
      <div><strong>Prestataire : ${contractor.fullName}</strong></div>
      <div><strong>Organisme : ${organization.name}</strong></div>
      <div><strong>Date : ${date}</strong></div>
      <div><strong>Type : ${type}</strong></div>
      <div><strong>Client : ${name}</strong></div>
      <div><strong>Validé par le client : ${validByClientTrans(validByClient)}</strong></div>
      <div><strong>Commentaire : ${comment}</strong></div>

      <p>Actuellement une nouvelle réservation est en attente d'approbation.
      S'il vous plaît visitez le panneau de modération.</p>
      `,
    })

    bot({
      msg: `${user.fullName} -  ${organization.name} vient de vous commander une formation`,
      subject: `Commande de nouvelle formation ${type} pour ${date}`, // Subject line
    })
  }
}

// contractor confirm new Event
// send to customer
const confirmNewEvent = (context, data) => {
  const {
    contractor,
    user,
    // organization,
    // start,
    date,
    type,
    name,
    comment,
    validByClient,
  } = data.payload

  // if mail confirmNewEvent
  if (process.env.VUE_APP_MAIL === 'true') {
    mail(context, {
      // to: 'julien.hautier@gmail.com, freeday.contact@gmail.com',
      to: user.email, // user.email
      subject: `Réservation approuvée pour ${date} ${type} `, // Subject line
      mailTitle: 'Réservation approuvée',
      html: `
      <p>Votre réservation ${type} pour ${date} a été approuvée.</p>

      <p>Veuillez nous faire parvenir en pièce(s) joint(s) le(s) document(s) relatif(s) à la session. <a href="mailto:${
        contractor.email
      }">${contractor.email}</a></p>

      <p>Information détaillées :</p>

      <div><strong>Prestataire : ${contractor.fullName}</strong></div>
      <div><strong>Date : ${date}</strong></div>
      <div><strong>Type : ${type}</strong></div>
      <div><strong>Client : ${name}</strong></div>
      <div><strong>Validé par le client : ${validByClientTrans(validByClient)}</strong></div>
      <div><strong>Commentaire : ${comment}</strong></div>
      `,
    })
  }
}

// customer cancel Event
// send to contractor
const cancelEvent = (context, data) => {
  // console.log('canncel-event')
  const { contractor, user, organization, date, type, name, comment, validByClient } = data.payload

  // if mail
  if (process.env.VUE_APP_MAIL === 'true') {
    // email client cancelEvent
    mail(context, {
      // to: 'julien.hautier@gmail.com, freedayapp.noreply@gmail.com',
      to: contractor.email,
      subject: `Réservation annulée vous devez validater pour ${date}`, // Subject line
      mailTitle: 'Réservation annulée',
      html: `
      <p>Vous devez approuver l'annulation d'une réservation pour ${date}</p>

      <p>Information détaillées :</p>

      <div><strong>Utilisateur : ${user.fullName}</strong></div>
      <div><strong>Prestataire : ${contractor.fullName}</strong></div>
      <div><strong>Organisme : ${organization.name}</strong></div>
      <div><strong>Date : ${date}</strong></div>
      <div><strong>Type : ${type}</strong></div>
      <div><strong>Client : ${name}</strong></div>
      <div><strong>Validé par le client : ${validByClientTrans(validByClient)}</strong></div>
      <div><strong>Commentaire : ${comment}</strong></div>
      `,
    })
  }
}

// contractor confirm cancel
// send to customer
const confirmCancelEvent = (context, data) => {
  // console.log('confirmCancelEvent', data)
  const {
    // contractor,
    user,
    // organization,
    date,
    type,
    name,
    comment,
    validByClient,
  } = data.payload

  // if mail
  if (process.env.VUE_APP_MAIL === 'true') {
    mail(context, {
      // to: 'julien.hautier@gmail.com', // , freedayapp.noreply@gmail.com',
      to: user.email,
      subject: `Votre réservation ${type} est annulée pour ${date}`, // Subject line
      mailTitle: 'Réservation annulée',
      html: `
      <p>Réservation annulées pour ${date}</p>

      <p>Information détaillées :</p>

      <div><strong>Prestataire : ${user.fullName}</strong></div>
      <div><strong>Date : ${date}</strong></div>
      <div><strong>Type : ${type}</strong></div>
      <div><strong>Client : ${name}</strong></div>
      <div><strong>Validé par le client : ${validByClientTrans(validByClient)}</strong></div>
      <div><strong>Commentaire : ${comment}</strong></div>
      `,
    })
  }
}

export default {
  newEvent,
  confirmNewEvent,
  cancelEvent,
  confirmCancelEvent,
}
