<template>
  <div>
    <VCardTitle>{{ $t('organizationCreate.pageTitle') }}</VCardTitle>

    <ValidationObserver
      ref="form"
      v-slot="{ invalid }"
    >
      <VForm>
        <div :class="$style.grid">
          <ValidationProvider
            v-slot="v"
            rules="required"
            name="Name"
          >
            <v-text-field
              v-model="form.name"
              :label="($t('organizationCreate.name') + '*')"
              :error-messages="v.errors[0]"
              outlined
              required
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="v"
            rules="required|numeric|min:10"
            name="Name"
          >
            <v-text-field
              v-model="form.organizationId"
              :label="($t('organizationCreate.number') + '*')"
              :error-messages="v.errors[0]"
              outlined
              required
            />
          </ValidationProvider>
        </div>

        <v-btn
          :disabled="invalid"
          color="success"
          @click="onClick"
          :loading="loading"
          block
          class="mr-2 my-4"
        >
          {{ $t('global.valid')}}
        </v-btn>
      </VForm>
    </ValidationObserver>
  </div>
</template>

<script>
// import { sanitize } from '@/utils/sanitize'
import { /* db, */ auth } from '@/firebase'
import {
  VForm, VTextField, VBtn, VCardTitle,
} from 'vuetify/lib'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'OrganizationCreate',
  props: {

  },
  components: {
    VForm, VTextField, VBtn, ValidationProvider, ValidationObserver, VCardTitle,
  },
  data() {
    return {
      form: {
        name: null,
        organizationId: null,
        users: [{
          docId: auth.currentUser.uid,
          email: auth.currentUser.email,
        }],
      },
      loading: false,
    }
  },
  computed: {
    orgaDocId() {
      return this.form.organizationId
    },
  },
  methods: {
    async oganizationExist() {
      const name = 'organization-get-before-create'

      await this.$store.dispatch('entities/get', {
        name,
        type: 'organizations',
        docId: this.orgaDocId,
      })

      const result = this.$store.getters['requests/result']({ name })

      return (result.length !== 0)
    },
    async onClick() {
      this.loading = true

      // check if organization exist in DB
      const orgaExist = await this.oganizationExist()

      if (orgaExist) {
        await this.updateOrganization()
      } else {
        await this.createNewOrganization()
      }

      // add Oganization to currentUser
      await this.$store.dispatch('entities/update', {
        name: 'currentUser-update',
        type: 'users',
        docId: this.$user.get().docId,
        data: {
          organization: {
            docId: this.orgaDocId,
            name: this.form.name,
          },
        },
      })
      this.loading = false
      this.$router.push({ name: 'organizationUsersList' })
    },
    async createNewOrganization() {
      await this.$store.dispatch('entities/set', {
        name: 'organization-create',
        type: 'organizations',
        docId: this.orgaDocId,
        data: this.form,
      })
    },
    async updateOrganization() {
      await this.$store.dispatch('entities/update', {
        name: 'organization-update',
        type: 'organizations',
        docId: this.orgaDocId,
        data: this.form.users,
      })

      await this.$store.dispatch('entities/get', {
        name: 'organization-get',
        type: 'organizations',
        docId: this.orgaDocId,
        force: true,
      })
    },
  },
}
</script>

<style lang="scss" module>
.error {
  color: tomato;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 1rem;
}
</style>
