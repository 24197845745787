import EventsList from '../views/EventsList.vue'
import Forgotten from '../modules/Login/Forgotten.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Organization from '../views/Organization.vue'
import Calendar from '../views/Calendar.vue'
import Storage from '../views/Storage.vue'
import Notifications from '../views/Notifications.vue'
import UsersList from '../views/UsersList.vue'
import OrganizationsList from '../views/OrganizationsList.vue'
import OrganizationUsersList from '../views/OrganizationUsersList.vue'
import EventRecovery from '../views/EventRecovery.vue'
import Skills from '../views/Skills.vue'
import Signout from '../views/Signout'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { header: true, footer: true },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { header: false, footer: false },
  },
  {
    path: '/forgotten',
    name: 'forgotten',
    component: Forgotten,
    meta: { header: false, footer: false },
  },
  {
    path: '/signout',
    name: 'signout',
    component: Signout,
  },
  {
    path: '/organization',
    name: 'organization',
    component: Organization,
    meta: {
      header: true,
      footer: true,
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    meta: {
      header: true,
      footer: true,
    },
  },
  {
    path: '/eventslist',
    name: 'eventslist',
    component: EventsList,
    meta: {
      header: true,
      footer: true,
    },
  },
  {
    path: '/skills',
    name: 'skills',
    component: Skills,
    meta: {
      header: true,
      footer: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: UsersList,
    meta: {
      header: true,
      footer: true,
    },
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: OrganizationsList,
    meta: {
      header: true,
      footer: true,
    },
  },
  {
    path: '/storage',
    name: 'storage',
    component: Storage,
    meta: {
      header: true,
      footer: true,
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      header: true,
      footer: true,
    },
  },
  {
    path: '/organizationUsersList',
    name: 'organizationUsersList',
    component: OrganizationUsersList,
    meta: {
      header: true,
      footer: true,
    },
  },
  {
    path: '/eventRecovery',
    name: 'eventRecovery',
    component: EventRecovery,
    meta: {
      header: true,
      footer: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { header: true, footer: true },
  },
]

export default routes
