import { db } from '@/firebase'
import Entities from '@/store/entities'
import { user } from '@/config/schemas'

// import Vue from 'vue'

const set = (context, data) => {
  // context.commit('set', data)
  const props = {
    type: 'users',
    data,
  }

  context.commit('entities/updateEntities', props, { root: true })
}

const fetch = async (context, options) => {
  const { name = 'user-fetch', docId, force } = options

  // Check if request is loaded
  const isLoaded = context.rootGetters['requests/loaded']({ name })
  // if data stored then do nothing
  if (isLoaded && !force) return

  // console.log('🚚 loadData user', options)
  // request loading
  await context.dispatch('requests/set', { name, loading: true }, { root: true })

  // build firestore query
  const query = db.collection('users')

  let response = {}
  await query.doc(docId).get().then((doc) => {
    response = doc.data()
  })

  // console.log('🔥 users loadData response', response)

  // normalizr
  const { entities, result } = Entities({
    data: response,
    schema: user,
  })

  // request result and loaded
  await context.dispatch('requests/set', {
    name,
    result,
    loaded: true,
  }, { root: true })

  // data store saved
  await context.dispatch('user/set', entities.users, { root: true })
}

const update = async (context, options) => {
  // console.log('🔥 user/update', { context, options })
  const { name = 'user-update', docId, data } = options

  // // Check if request is loaded
  // const isLoading = context.rootGetters['requests/loading']({ name: requestName })
  // // if data stored then do nothing
  // if (isLoading) return

  // request loading
  await context.dispatch('requests/set', { name, loading: true }, { root: true })

  try {
    // call firestore
    await db.collection('users').doc(docId).set(data, {
      merge: true,
    })

    // read new user from firebase
    await context.dispatch('fetch', {
      docId,
      force: true,
    })

    // request result and loaded
    await context.dispatch('requests/set', {
      name,
      result: [docId],
      loaded: true,
    }, {
      root: true,
    })
  } catch (e) {
    console.log('error', e)
    // request result and loaded
    await context.dispatch('requests/set', {
      name,
      result: [],
      loaded: true,
      errors: e,
    }, {
      root: true,
    })

    const err = new Error('[User] set', e)
    window.newrelic.noticeError(err)
  }
}

export default {
  set,
  fetch,
  update,
}
