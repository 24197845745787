/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('🤖 App is being served from cache by a service worker.')
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('🎃 New content is available; please refresh.')
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}

// import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production') {
//   register(`${process.env.BASE_URL}service-worker.js`, {
//     ready() {
//       console.log('🤖 App is being served from cache by a service worker.')
//     },
//     registered() {
//       console.log('Service worker has been registered.')
//     },
//     cached() {
//       console.log('Content has been cached for offline use.')
//     },
//     updatefound() {
//       console.log('New content is downloading.')
//     },
//     updated() {
//       console.log('🎃 New content is available; please refresh.')
//       // Envoyer un message au service worker pour déclencher la mise à jour forcée
//       if (navigator.serviceWorker.controller) {
//         console.log('navigator.serviceWorker.controller')
//         navigator.serviceWorker.controller.postMessage('update')
//       }
//       if (
//         // eslint-disable-next-line no-restricted-globals
//         confirm("Une nouvelle version de l'application est disponible. Voulez-vous mettre à jour ?")
//       ) {
//         // Forcer la mise à jour du service worker
//         if (navigator.serviceWorker.controller) {
//           navigator.serviceWorker.controller.postMessage('update')
//         }
//       }
//     },
//     offline() {
//       console.log('No internet connection found. App is running in offline mode.')
//     },
//     error(error) {
//       console.error('Error during service worker registration:', error)
//     },
//   })
// }

// // Écouter les messages du service worker
// navigator.serviceWorker.addEventListener('message', (event) => {
//   console.log('message', event)

//   if (event.data === 'update') {
//     // Afficher un message à l'utilisateur pour l'informer de la mise à jour
//     if (
//       // eslint-disable-next-line no-restricted-globals
//       confirm("Une nouvelle version de l'application est disponible. Voulez-vous mettre à jour ?")
//     ) {
//       console.log('confirm true')
//       // Forcer la mise à jour du service worker
//       if (navigator.serviceWorker.controller) {
//         navigator.serviceWorker.controller.postMessage('update')

//         // Recharger la page
//         // window.location.reload(true)
//         console.log('location with timestamp 04')
//         // Skip waiting to activate the new service worker immediately
//         navigator.serviceWorker.skipWaiting()
//         // Redirect
//         window.location.href = `${window.location.pathname}?timestamp=${new Date().getTime()}`
//       }
//     }
//   }
// })
