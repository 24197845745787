let intervalId

const EventsProvider = {
  props: {
    organizationId: {
      type: String,
    },
    date: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  mounted() {
    // this.loadData()
    intervalId = setInterval(this.refreshNeeded, 3601000) // 1h01 equal 3601000
  },
  beforeDestroy() {
    clearInterval(intervalId)
  },
  computed: {
    data() {
      const events = this.$store.getters['entities/list']({
        type: 'events',
      })

      return [...events].filter((event) => event.status !== 'delete')
    },
    loading() {
      return this.$store.getters['requests/loading']({ name: 'events-get' })
    },
  },
  watch: {
    date(val, oldval) {
      if (val !== oldval) {
        this.loadData()
      }
    },
  },
  methods: {
    refreshNeeded() {
      // const refresh = window.confirm('We need to refresh the datas')
      // if (refresh) {
      //   this.$router.go()
      //   // this.loadData()
      // }
      this.loadData()
    },
    async loadData() {
      console.log('🍋 event loadData')
      const d = new Date(this.date)
      const month = d.getMonth() + 1 // january = 0
      const fullyear = d.getFullYear()

      const where = [
        // {
        //   key: 'organization.docId',
        //   operator: '==',
        //   value: this.organizationId,
        // },
        {
          key: 'date.month',
          operator: '==',
          value: month,
        },
        {
          key: 'date.year',
          operator: '==',
          value: fullyear,
        },
      ]

      // filter firestore if this is not a contractor
      // and have an organzation id
      // retrieve only events for this organizations
      if (!this.user.role.contractor && this.organizationId) {
        where.unshift({
          key: 'organization.docId',
          operator: '==',
          value: this.organizationId,
        })
      }

      // filter firestore if this is a contractor
      // retrieve only events fo this contractor
      if (this.user.role.contractor) {
        where.unshift({
          key: 'contractor.docId',
          operator: '==',
          value: this.user.docId,
        })
      }

      await this.$store.dispatch('entities/list', {
        name: `events-get-${this.date}`,
        type: 'events',
        queries: {
          where,
        },
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      loading: this.loading,
      data: this.data,
    })
  },
}

export default EventsProvider
