<template>
  <VCard
    class="mx-auto mt-8"
    max-width="1200px"
    outlined
  >
    <VCardTitle>Ajouter un event</VCardTitle>
    <VCardText>

    <UsersProvider>
      <div slot-scope="{ data, loading }" v-if="$user.isAdmin()">
        <div v-if="loading">{{$t('global.loading')}}</div>

        <div v-else>
          <div>
            <v-select
              :items="items(filteredUsers('customer', data))"
              label="Client"
              @change="onChangeCustomer"
              hide-details
            ></v-select>
          </div>
          <div>
            <v-select
              :items="items(filteredUsers('contractor', data))"
              label="Formateur"
              @change="onChangeContractor"
              hide-details
            ></v-select>
          </div>
        </div>

        <DatePickerMultiple
          v-if="customer && contractorDocId"
          :events="[]"
          :customer="customer"
          :contractorId="contractorDocId"
          :key="contractorDocId"
          class="mt-8"
        />
      </div>
    </UsersProvider>
  </VCardText>
  </VCard>
</template>

<script>
import {
  VCard, VCardTitle, VCardText, VSelect,
} from 'vuetify/lib'
import DatePickerMultiple from '@/modules/Calendar/components/DatePickerMultiple.vue'
import UsersProvider from '@/modules/Users/UsersProvider'
import {logEvent} from '@/utils/logEvent'

export default {
  name: 'EventRecoveryComponent',
  components: {
    VCard,
    VCardTitle,
    VCardText,
    VSelect,
    UsersProvider,
    DatePickerMultiple,
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - EventRecovery',
  },
  data() {
    return {
      customer: {},
      contractorDocId: '',
    }
  },
  computed: {
  },
  created() {
    logEvent('eventRecoveryPage')
  },
  methods: {
    filteredUsers(type, data) {
      return data.filter((user) => user.role[type])
    },
    items(data) {
      return data.reduce((accumulator, currentValue) => {
        accumulator.push({
          text: currentValue?.person.email,
          value: currentValue,
        })
        return accumulator
      }, [])
    },
    onChangeCustomer(value) {
      this.customer = value
    },
    onChangeContractor(value) {
      this.contractorDocId = value.docId
    },
  },
}
</script>

<style lamg="scss" module>

</style>
