<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('organizationUsersList.title') }}
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('global.search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <VDataTable
        :headers="headers"
        :items="filterdUsers"
        :search="search"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn class="mx-2" fab dark x-small color="red darken-2">
            <v-icon small dark @click="onDelete(item)"> fa fa-times </v-icon>
          </v-btn>
        </template>
      </VDataTable>
    </v-card>

    <!-- <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">lorem ipsum dolor</v-card-title>

        <v-card-text>
          lorem ipsum dolor
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="deleteAction"
          >
            Valid
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
/* eslint-disable */
import { /* db, */ functions } from '@/firebase'
import {
  VDataTable,
  VIcon,
  VListItem,
  VListItemContent,
  VListItemTitle,
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VBtn,
  VSpacer,
} from 'vuetify/lib'
import userMixin from '@/components/mixins'

export default {
  name: 'OrganizationListUsers',
  components: {
    VDataTable,
    VIcon,
    VListItem,
    VListItemContent,
    VListItemTitle,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VBtn,
    VSpacer,
  },
  props: {
    users: {
      type: Array,
      defaut: [],
    },
  },
  data() {
    return {
      dialog: false,
      docId: null,
      search: '',
      headers: [
        {
          text: this.$t('organizationUsersList.email'),
          align: 'start',
          sortable: false,
          value: 'person.email',
        },
        { text: this.$t('organizationUsersList.firstname'), value: 'person.firstName' },
        { text: this.$t('organizationUsersList.lastname'), value: 'person.lastName' },
        // {
        //   text: 'Actions',
        //   value: 'actions',
        //   align: 'center',
        //   sortable: false,
        // },
      ],
    }
  },
  mixins: [userMixin],
  computed: {
    organizationId() {
      return this.$user.get()?.organization?.docId
    },
    filterdUsers() {
      // remove disabled user
      return this.users.filter((u) => u.disabled !== true)
    },
  },
  methods: {
    onDelete(item) {
      this.dialog = true
      this.docId = item.docId
    },
    async deleteAction() {
      const docId = this.docId
      try {
        const deleteUser = functions.httpsCallable('disabledUser')
        await deleteUser({ docId }).then((res) => {
          // delete res from users entity
          this.$store.commit('entities/deleteEntity', {
            type: 'users',
            docId: res.data.docId,
          })
        })
      } catch (e) {
        console.log('deleteUser error', e)

        const err = new Error('[OrganizationListUsers] deleteAction', e)
        window.newrelic.noticeError(err)
      }
      this.dialog = false
    },
  },
}
</script>

<style lang="scss" module></style>
