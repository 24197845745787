<template>
  <div>
    <v-btn v-if="isValidated" class="mx-2" fab dark x-small color="green">
      <v-icon small dark @click="accepted()"> fa fa-plus </v-icon>
    </v-btn>

    <v-btn class="mx-2" fab dark x-small color="red darken-2">
      <v-icon small dark @click="onDelete()"> fa fa-times </v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline">{{ $t('eventsList.deleteAlert.head') }}</v-card-title>

        <v-card-text>
          {{ $t('eventsList.deleteAlert.text') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text class="mr-3" @click="dialog = false">
            {{ $t('global.cancel') }}
          </v-btn>

          <v-btn color="red" dark @click="deleteAction">
            {{ $t('global.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VBtn } from 'vuetify/lib'

import colors from '@/utils/colors'

export default {
  components: {
    VBtn,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    baseEvents: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      docId: null,
    }
  },
  computed: {
    savedEvents() {
      // store event into store
      // because on delete i loose it
      return this.$store.getters['eventActions/get']()
    },
    contractorId() {
      return this.$user.get()?.docId
    },
    isValidated() {
      const item = this.event
      const baseEvent = this.baseEvents.dates
      // if event existing in baseEvents then event can't be validated
      if (baseEvent === undefined) return true

      return !baseEvent.includes(item.start) && item.status !== 'cancel'
    },
  },
  methods: {
    async accepted() {
      const item = this.event
      // @todo send email for updated event
      await this.$store.dispatch('entities/update', {
        name: 'event-update',
        type: 'events',
        docId: item.docId,
        data: {
          color: item.color === colors.notAvailable ? colors.notAvailable : colors.fulfilled,
          status: 'fulfilled', // pending, fulfilled, rejected
        },
      })

      const { dates } = this.baseEvents

      let newDates
      if (typeof dates !== 'undefined') {
        // newDates = `${item.start}, ${dates}`
        const dateArray = item.start.split(',')
        dateArray.push(dates)
        newDates = dateArray.join(',')
      } else {
        newDates = item.start
      }

      // update baseEvents
      await this.$store.dispatch('entities/update', {
        name: 'base-events-update',
        type: 'baseEvents',
        docId: this.contractorId,
        data: {
          dates: newDates,
        },
      })

      this.sendConfirmEmail(item)
    },
    async onDelete() {
      this.dialog = true
      this.docId = this.event.docId

      // saved current event into store
      // on delete losse it and can't send the email properly
      await this.$store.dispatch('eventActions/set', {
        event: this.event,
      })
    },
    async deleteAction() {
      const event = this.savedEvents

      // update event with soft delete
      await this.$store.dispatch('entities/update', {
        name: `event-delete-${this.docId}`,
        type: 'events',
        docId: this.docId,
        data: {
          status: 'delete',
        },
      })

      // check if an event existing with status fulfilled
      // before delete in baseEvents
      // admin see have access at all events
      const name = `events-get-${event.start}`
      await this.$store.dispatch('entities/list', {
        name,
        type: 'events',
        queries: {
          where: [
            {
              key: 'start',
              operator: '==',
              value: event.start,
            },
            {
              key: 'status',
              operator: '==',
              value: 'fulfilled',
            },
          ],
        },
      })

      const res = this.$store.getters['requests/result']({ name })

      if (res.length === 0) {
        // Delete entry date to eventBases
        const baseEvents = this.baseEvents.dates
        const newBaseEvents = baseEvents
          .split(',')
          .filter((item) => item !== event.start)
          .join(',')

        await this.$store.dispatch('entities/update', {
          name: 'base-events-update',
          force: true,
          type: 'baseEvents',
          docId: this.contractorId,
          data: {
            dates: newBaseEvents,
          },
        })
      }

      this.dialog = false

      this.sendConfirmCancelEmail(event)
    },
    async sendConfirmEmail(payload) {
      // console.log('sendConfirmEmail', payload.docId)

      payload.date = this.$d(new Date(payload.start), 'digits')

      try {
        this.$store.dispatch('mail/confirmNewEvent', { payload })
      } catch (error) {
        const err = new Error('sendConfirmEmail', error)
        window.newrelic.noticeError(err)
      }
    },
    async sendConfirmCancelEmail(payload) {
      // console.log('sendConfirmCancelEmail', payload.docId)

      payload.date = this.$d(new Date(payload.start), 'digits')
      try {
        this.$store.dispatch('mail/confirmCancelEvent', { payload })
      } catch (error) {
        const err = new Error('sendConfirmCancelEmail', error)
        window.newrelic.noticeError(err)
      }
    },
  },
}
</script>

<style></style>
