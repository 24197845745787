<template>
  <div>
    <VCardTitle>{{ $t('organizationEdit.pageTitle') }}</VCardTitle>

    <ValidationObserver
      ref="form"
      v-slot="{ invalid }"
    >
      <VForm>
        <div :class="$style.grid">
          <ValidationProvider
            v-slot="v"
            rules="required"
            name="Name"
          >
            <v-text-field
              v-model="form.name"
              :label="($t('organizationCreate.name') + '*')"
              :error-messages="v.errors[0]"
              outlined
              required
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="v"
            rules="required|numeric"
            name="Name"
          >
            <v-text-field
              v-model="form.organizationId"
              :label="($t('organizationCreate.number') + '*')"
              :error-messages="v.errors[0]"
              outlined
              required
              :disabled="true"
            />
          </ValidationProvider>

            <v-btn
            :disabled="invalid"
            color="success"
            @click="updateOrganization"
            :loading="loading"
            block
            x-large
          >
            {{ $t('global.valid')}}
          </v-btn>
        </div>

      </VForm>
    </ValidationObserver>
  </div>
</template>

<script>
// import { sanitize } from '@/utils/sanitize'
// import { /* db, */ auth } from '@/firebase'
import {
  VForm, VTextField, VBtn, VCardTitle,
} from 'vuetify/lib'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'OrganizationCreate',
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  components: {
    VForm, VTextField, VBtn, ValidationProvider, ValidationObserver, VCardTitle,
  },
  data() {
    const { name, docId: organizationId } = this.organization
    return {
      form: {
        name,
        organizationId,
      },
      loading: false,
    }
  },
  methods: {
    async updateOrganization() {
      const docId = this.form.organizationId
      await this.$store.dispatch('entities/update', {
        name: `organization-update-${docId}`,
        type: 'organizations',
        docId,
        data: this.form,
      })
    },
  },
}
</script>

<style lang="scss" module>
.error {
  color: tomato;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 1rem;
}
</style>
