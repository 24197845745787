const auth = {
  namespaced: true,
  // actions,
  getters: {
    userId: (state) => state.userId,
    $user: (state, __, ___, rootGetters) => ({
      get: () => (
        state.userId
          ? rootGetters['entities/get']({
            type: 'users',
            key: state.userId,
          })
          : {}
      ),
      isAdmin: () => {
        const user = state.userId
          ? rootGetters['entities/get']({
            type: 'users',
            key: state.userId,
          })
          : {}
        const role = user?.role || {}
        const roles = Object.keys(role)
        return roles.includes('admin')
      },
    }),
  },
  mutations: {
    set: (state, props) => {
      state.userId = props
    },
  },
  state: {
    userId: '',
  },
}

export default auth
