<template>
  <div>
     <v-card>
      <v-card-title>
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('global.search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <VDataTable
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:item.createAt="{ item }">
          {{ $d(new Date(item.createAt),'digits') }}
        </template>

        <!-- <template v-slot:item.users="{ item }">
          <v-chip color="red" dark>{{ item.users.length }}</v-chip>
        </template> -->

       <template v-slot:item.actions="{ item }">
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="green"
          >
            <v-icon
              small
              dark
              @click="onEdit(item)"
            >
              fa fa-edit
            </v-icon>

          </v-btn>
        </template>
      </VDataTable>
    </v-card>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Admin</v-toolbar-title>
        <!-- <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="dialog = false"
          >
            Save
          </v-btn>
        </v-toolbar-items> -->
      </v-toolbar>

      <v-card>
        <v-card-title class="headline">{{ $t('organizationAdmin.head') }}</v-card-title>

        <v-card-text>
          {{ $t('organizationAdmin.text') }}

          <OrganizationEdit :organization="itemEdited" :key="itemEdited.docId"></OrganizationEdit>

          <UsersProvider :organizationId="itemEdited.docId">
            <div slot-scope="{ data, loading }">
              <div v-if="loading">{{$t('global.loading')}}</div>

              <div v-else :class="$style.grid">
                <Users :users="data"/>
                <OrganizationAddUser
                  :organizationId="itemEdited.docId"
                  :organizationName="itemEdited.name"
                />
              </div>
            </div>
          </UsersProvider>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  VDataTable,
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VIcon,
  VBtn,
  VSpacer,
  VTextField,
  VToolbar,
  // VToolbarItems,
} from 'vuetify/lib'

import UsersProvider from '@/modules/Organization/UsersProvider'
import Users from '@/modules/Users/Users.vue'
import OrganizationAddUser from '@/modules/Organization/OrganizationAddUser.vue'
import OrganizationEdit from '@/modules/Organization/OrganizationEdit.vue'

export default {
  name: 'OrganizationsComponent',
  components: {
    VDataTable,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VIcon,
    VBtn,
    VSpacer,
    VTextField,
    VToolbar,
    // VToolbarItems,
    UsersProvider,
    Users,
    OrganizationAddUser,
    OrganizationEdit,
  },
  props: {
    items: {
      type: Array,
    },
  },
  data: () => ({
    itemEdited: {},
    dialog: false,
    docId: null,
    search: '',
    headers: [
      {
        text: 'name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'createAt', value: 'createAt' },
      { text: 'organizationId', value: 'organizationId' },
      // { text: 'nb users', value: 'users' },
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
  }),
  computed: {
  },
  created() {
  },
  methods: {
    onEdit(item) {
      this.itemEdited = item
      this.dialog = true
    },
    onDelete() {
      console.log('onDelete')
    },
  },
}
</script>

<style lang="scss" module>
.grid {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

</style>
