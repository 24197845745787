<template>
  <VCard
    class="mx-auto mt-8"
    max-width="1200px"
    outlined
  >
    <VCardTitle>{{ $t('eventsList.pageTitle') }}</VCardTitle>
    <VCardText>
      <EventsList />
    </VCardText>
  </VCard>
</template>

<script>

import { VCard, VCardTitle, VCardText } from 'vuetify/lib'
import { EventsList } from '@/modules/EventsList'
import {logEvent} from '@/utils/logEvent'

export default {
  name: 'EventsListView',
  components: {
    VCard,
    VCardTitle,
    VCardText,
    EventsList,
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - EventsList',
  },
  computed: {
  },
  created() {
    // Record custom event to analytics "eventsListPage"
    logEvent('eventsListPage')
  },
}
</script>

<style lamg="scss" module>

</style>
