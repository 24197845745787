<template>
  <div>
    <component :is="component" />
  </div>
</template>

<script>
import Calendar from '@/views/Calendar.vue'
import HomeAdmin from '@/modules/Home/HomeAdmin.vue'
import HomeContractor from '@/modules/Home/HomeContractor.vue'
import HomeCustomer from '@/modules/Home/HomeCustomer.vue'
import {logEvent} from '@/utils/logEvent'

export default {
  name: 'HomeComponent',
  components: {
    Calendar,
    HomeAdmin,
    HomeContractor,
    HomeCustomer,
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - Home',
  },
  computed: {
    component() {
      const user = this.$user.get()
      const role = user?.role || {}
      const roles = Object.keys(role)

      if (roles.includes('admin')) {
        return HomeAdmin
      }

      if (roles.includes('contractor')) {
        return HomeContractor
      }

      return HomeCustomer
    },
  },
  created() {
    // Record custom event to analytics "homePage"
    logEvent('homePage')
  },
}
</script>
