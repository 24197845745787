<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    white
  >
    <div class="d-flex align-center">
      <VAppBarNavIcon @click.stop="onClick"></VAppBarNavIcon>

      <VToolbarTitle>FreeDay</VToolbarTitle>
    </div>

    <v-spacer />

    <v-btn
      :to="{name: 'about'}"
      text
    >
      <span class="mr-2">
        <v-icon>fa fa-user-circle</v-icon> <span v-if="isVisible">{{ username }}</span>
      </span>
    </v-btn>

    <v-btn
      icon
      to="signout"
      @click.stop="onSignOut"
    >
      <span>
        <v-icon>fa fa-sign-in-alt</v-icon>
      </span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {
  VAppBar,
  VAppBarNavIcon,
  VToolbarTitle,
  VSpacer,
  VBtn,
  VIcon,
} from 'vuetify/lib'

import {logEvent} from '@/utils/logEvent'

import userMixin from '@/components/mixins'

export default {
  name: 'AppBar',
  components: {
    VAppBar,
    VAppBarNavIcon,
    VToolbarTitle,
    VSpacer,
    VBtn,
    VIcon,
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  mixins: [userMixin],
  computed: {
    isVisible() {
      return this.$vuetify.breakpoint.smAndUp
    },
  },
  methods: {
    onClick() {
      this.$emit('input', !this.value)
    },
    onSignOut() {
      // Record custom event to analytics "signOut"
      logEvent('signOut')

      this.$emit('input', false)
    },
  },
}
</script>

<style>

</style>
