<template>
  <v-row justify="center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="start"
          label="event startedAt"
          prepend-icon="fa-calendar"
          readonly
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="start"
        :events="arrayEvents"
        :event-color="colorsEvents"
        :allowed-dates="allowedDates"
        :first-day-of-week="1"
        locale="fr"
        scrollable
        @input="menu = false"
      />
    </v-menu>

    <VSpacer />

    <v-menu
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="end"
          label="event endedAt"
          prepend-icon="fa-calendar"
          readonly
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="end"
        :events="arrayEvents"
        :event-color="colorsEvents"
        :allowed-dates="allowedDates"
        :first-day-of-week="1"
        locale="fr"
        scrollable
        @input="menu2 = false"
      />
    </v-menu>

    <VSpacer />

    <v-autocomplete
      v-model="type"
      :items="types"
      color="white"
      hide-no-data
      hide-selected
      item-text="Habilitation"
      item-value="API"
      label="Public APIs"
      placeholder="Start typing to Search"
      return-object
    />

    <VSpacer />

    <v-text-field
      v-model="eventName"
      label="event name"
    />

    <v-btn
      color="grey"
      :disabled="disabled"
      @click="onSubmit"
    >
      {{ $t('global.valid')}}
    </v-btn>
    label: MissionClientName<br>
    habilitation: liste<br>
    annulation client<br>
    validation technicien<br>
  </v-row>
</template>

<script>
import {
  VMenu, VTextField, VSpacer, VRow, VDatePicker, VBtn,
} from 'vuetify/lib'

const TYPES = [
  'Habilitation électrique basse tension',
  'Habilitation électrique haute tension',
  'Habilitation électrique hbasse et haute tension',
  'Notions de base en électricité',
  'Habilitation mécanique',
  'Sécurité incendie',
]

export default {
  name: 'DatePicker',
  components: {
    VMenu,
    VTextField,
    VSpacer,
    VRow,
    VDatePicker,
    VBtn,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      picker: new Date().toISOString().substr(0, 10),
      start: null,
      end: null,
      menu: false,
      menu2: false,
      eventName: 'test',
      types: TYPES,
      type: null,
    }
  },
  computed: {
    disabled() {
      return (!this.start && !this.end)
    },
    colorsEvents() {
      return (date) => {
        const result = this.events.reduce((acc, cur) => {
          if (cur.start.substr(0, 10) === date) {
            acc.push(cur.color)
          }
          return acc
        }, [])
        return result
      }
    },
    arrayEvents() {
      const result = this.events.reduce((acc, cur) => {
        acc.push(cur.start.substr(0, 10))
        return acc
      }, [])
      return result
    },
  },
  methods: {
    allowedDates(val) {
      // disable weekend date / firstDay and event day
      const firstDay = (new Date(val) > new Date(this.picker))
      const weekendDay = ![0, 6].includes(new Date(val).getDay())
      const eventDay = !this.arrayEvents.includes(val)

      return firstDay && weekendDay && eventDay
    },
    setDate(val) {
      this.start = val
      this.end = val
    },
    async onSubmit() {
      if (!this.start) return false

      const { docId, person, organization } = this.$user.get()

      const date = new Date(this.start)
      const event = {
        date: {
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate(),
        },
        name: this.eventName,
        start: this.start,
        end: this.end,
        status: 'pending',
        type: this.type,
        color: 'indigo',
        user: {
          docId,
          email: person.email,
        },
        organization,
        contractor: {
          docId: 'test',
          email: 'test§acme.com',
        },
      }

      await this.$store.dispatch('entities/set', {
        name: 'new-event',
        type: 'events',
        data: event,
      }).then(() => {
        this.start = null
        this.end = null
      })

      return true
    },
  },

}
</script>

<style>

</style>
