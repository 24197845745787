const fr = {
  global: {
    email: 'E-mail',
    loading: 'Chargement...',
    reserved: 'Réservé',
    valid: 'Valider',
    cancel: 'Annuler',
    cancelRequest: 'Annuler la demande',
    close: 'Fermer',
    delete: 'Effacer',
    search: 'Rechercher',
    send: 'Envoyer',
    pending: 'En attente',
    typingForSearch: 'Tapper pour rechercher',
    all: 'Tous',
    yes: 'oui',
    no: 'non',
  },
  veevalidate: {
    errors: {
      email: 'Adresse mail non valide',
      min: 'Pas assez long',
      required: 'Ce champ est obligatoire',
      password: 'Ce champ doit contenir A-Z, a-z, 0-9',
      numeric: 'Ce champ doit contenir des chiffres',
    },
  },
  login: {
    password: 'Mot de passe',
    hints: {
      pwd: 'Au moins 6 caractères, majuscule, miniscule, chiffres',
    },
    signin: 'Connexion',
    signup: 'Inscription',
    signout: 'Déconnexion',
    forgottenpwd: 'Mot de passe oublié',
  },
  navigation: {
    home: 'Accueil',
    organization: 'Organisme de formation',
    calendar: 'Calendrier',
    about: 'Ma page',
    skills: 'Compétences',
    eventRecovery: 'Ajout event',
    eventsList: 'Liste des réservations',
    usersList: 'Liste de utilisateurs',
    organizationsList: 'List des organismes',
    label: 'Intitulé',
    notifications: 'Notifications',
  },
  profile: {
    pageTitle: 'Ma page',
    organization: 'Organisme de formation',
    email: 'E-mail',
    firstname: 'Prénom',
    lastname: 'Nom',
    phonenumber: 'Téléphone',
    displayname: 'pseudonyme',
  },
  message: {
    hello: 'Bonjour, {name}',
  },
  home: {
    pageTitle: 'Bienvenue',
  },
  organization: {
    pageTitle: 'Organisme de formation',
    pageTitle2: 'liste des utilisateurs',
    baseline:
      'ici vous pouvez ajouter ou supprimer de nouveux utilisateurs rattachés à votre centre de formation',
  },
  organizationCreate: {
    pageTitle: 'Création d\'un organisme de formation',
    name: 'Nom',
    number: 'Numéro d\'organisme',
  },
  organizationAddUser: {
    title: 'Ajout d\'un nouvel utilisateur',
    email: 'E-mail*',
  },
  organizationUsersList: {
    title: 'Liste des utilisateurs',
    email: 'email',
    firstname: 'prénom',
    lastname: 'nom',
  },
  usersList: {
    pageTitle: 'Liste des utilisateurs',
    deleteAlert: {
      head: 'Supprimer l\'utilisateur',
      text: 'La suppression d\'utilisateur est permanente. Les accès et les données de cet utilisateur seront perdus',
    },
    email: 'email',
    firstname: 'Prénom',
    lastname: 'Nom',
    admin: 'Admin',
    customer: 'Client',
    organization: 'Organisme de formation',
    actions: 'Actions',
  },
  calendar: {
    pageTitle: 'Calendrier',
    today: 'Aujourd\'hui',
    day: 'jour',
    week: 'semaine',
    month: 'mois',
    organization: 'Organisme de formation :',
    author: 'Auteur :',
    type: 'Formation :',
    contractor: 'Formateur :',
    cost: 'Coût prestation :',
    comment: 'Détail réservation :',
    valid_by_client: 'Validé par le client :',
    accessDenied:
      'Vous ne pouvez pas avoir accès au calendrier si vous n\'avez pas renseigner votre organisme de formation',
    configEntity: 'Configurer son organisme de formation',
    notice: 'Légende des couleurs*',
  },
  contractorSelector: {
    chooseYourContractor: 'Choisissez un prestataire',
  },
  datepicker: {
    formationsAvailable: 'Formations disponible',
    eventName: 'Nom du client',
    cost: 'Coût prestation',
    comment: 'Détail réservation :',
    dateAlreadySubmited: 'La date {date} a déjà été soumise',
  },
  eventsList: {
    pageTitle: 'Réservations en attentes',
    headline: {
      course: 'Société',
      customer: 'Nom',
      date: 'Date',
      organization: 'Organisme de formation',
      validByClient: 'Validé par le client ',
      formationType: 'Formation',
      cost: 'Coût en euros',
      status: 'Status',
      actions: 'Actions',
    },
    deleteAlert: {
      head: 'Supprimer la formation',
      text: 'La suppression la formation est permanente.',
    },
    filterByMonth: 'Filtrer par mois',
  },
  notifications: {
    pageTitle: 'Envoie de notification',
    title: 'Title',
    message: 'Message',
  },
  skills: {
    pageTitle: 'Gérer vos prestations',
    create: {
      label: 'Intitulé de la compétence',
    },
    skillsList: 'Consulter la liste de vos compétences',
    addNewSkill: 'Ajouter une nouvelle compétence',
    deleteAlert: {
      head: 'Supprimer la compétence',
      text: 'La suppression de la compétence est permanente.',
    },
  },
  organizationList: {
    pageTitle: 'Gérer vos organismes',
  },
  organizationAdmin: {
    head: 'Gérer l\'organisation',
    text: 'Vous pouvez éditer le nom de l\'organisation, ainsi que gérer les utilisateurs.',
  },
  organizationEdit: {
    pageTitle: 'Edition du nom l\'organisme',
  },
}

export default fr
