<template>
  <div :class="$style.wrapper">
    <div>
      <div :class="$style.logo">FreeDay</div>

      <VCard
        class="mx-auto"
        max-width="360"
        outlined
      >
        <VCardTitle>Réinitialiser son mot de passe</VCardTitle>
        <VCardText>

          <div v-if="!submit">
            <ValidationObserver
              ref="form"
              v-slot="{ invalid }"
            >
              <ValidationProvider
                v-slot="v"
                rules="required|email"
                name="Email"
              >
                <v-text-field
                  v-model="email"
                  type="text"
                  label="email"
                  required
                  outlined
                  :error-messages="v.errors[0]"
                />
              </ValidationProvider>

              <div class="text-center my-4">
                <v-btn
                  :disabled="(invalid)"
                  color="info"
                  @click="sendPasswordResetEmail"
                >
                  Valider
                </v-btn>
              </div>
            </ValidationObserver>
          </div>
          <div v-else>
            <p>Votre demande a bien été prise en compte, un email vient de vous être envoyé</p>
          </div>

          <div class="text-center">
            <router-link :to="{name: 'login'}">
              Page d'accueil
            </router-link>
          </div>
        </VCardText>
      </VCard>

    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase'
import {logEvent} from '@/utils/logEvent'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  VCard,
  VCardTitle,
  VCardText,
  // VForm,
  VTextField,
  VBtn,
} from 'vuetify/lib'

export default {
  name: 'ForgottenComponent',
  components: {
    VCard,
    VCardTitle,
    VCardText,
    // VForm,
    VTextField,
    VBtn,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: null,
      submit: false,
    }
  },
  methods: {
    // auth.sendPasswordResetEmail(email).then(res => console.log('res', res))
    sendPasswordResetEmail() {
      // Record custom event to analytics "forgottenPassword"
      logEvent('forgottenPassword')

      auth.sendPasswordResetEmail(this.email)
        .then(() => {
          this.submit = true
        })
        .catch((error) => {
          this.$refs.form.setErrors({
            Email: [error.message],
          })

          const err = new Error('[Forgotten] sendPasswordResetEmail', error)
          window.newrelic.noticeError(err)
        })
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.logo {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}
</style>
