export const customer = [
  {
    routeName: 'home',
    label: 'navigation.home',
    icon: 'fa fa-home',
  },
  {
    routeName: 'calendar',
    label: 'navigation.calendar',
    icon: 'fa fa-calendar',
  },
  {
    routeName: 'organizationUsersList',
    label: 'navigation.usersList',
    icon: 'fa fa-users',
  },
  // {
  //   routeName: 'organization',
  //   label: 'navigation.organization',
  //   icon: 'fa fa-building',
  // },
  {
    routeName: 'about',
    label: 'navigation.about',
    icon: 'fa fa-user',
  },
]

export const contractor = [
  {
    routeName: 'home',
    label: 'navigation.home',
    icon: 'fa fa-home',
  },
  {
    routeName: 'eventslist',
    label: 'navigation.eventsList',
    icon: 'fa fa-list',
  },
  {
    routeName: 'calendar',
    label: 'navigation.calendar',
    icon: 'fa fa-calendar',
  },
  {
    routeName: 'skills',
    label: 'navigation.skills',
    icon: 'fa fa-award',
  },
  {
    routeName: 'about',
    label: 'navigation.about',
    icon: 'fa fa-user',
  },
]

export const admin = [
  {
    routeName: 'home',
    label: 'navigation.home',
    icon: 'fa fa-home',
  },
  {
    routeName: 'users',
    label: 'navigation.usersList',
    icon: 'fa fa-users',

  },
  {
    routeName: 'organizations',
    label: 'navigation.organizationsList',
    icon: 'fa fa-building',

  },
  {
    routeName: 'eventRecovery',
    label: 'navigation.eventRecovery',
    icon: 'fa fa-building',

  },
  {
    routeName: 'notifications',
    label: 'navigation.notifications',
    icon: 'fa fa-bell',
  },
  // {
  //   routeName: 'storage',
  //   label: 'navigation.storage',
  //   icon: 'fa fa-box',
  // },
]

// export const navigation = (role) => ((role.admin !== 'undefined')
//   ? [...customer, ...admin,
//   ] : customer)

export const navigation = (role) => {
  if (role?.admin === true) {
    return [/* ...customer, ...contractor, */...admin]
  }

  if (role?.customer === true) {
    return customer
  }

  return contractor

  // return ((role.admin !== 'undefined')
  //   ? [...customer, ...admin] : customer)
}
