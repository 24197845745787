import Vue from 'vue'
import Vuex from 'vuex'

import entities from '@/store/modules/entities'
import user from '@/store/modules/user'
import organization from '@/store/modules/organization'
import requests from '@/store/modules/requests'
import mail from '@/store/modules/mail'
import eventActions from '@/store/modules/eventActions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    entities,
    requests,
    user,
    organization,
    mail,
    eventActions,
  },
})
