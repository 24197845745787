/* eslint-disable class-methods-use-this */
import firebase from 'firebase/app'
import { db } from '@/firebase'
import Entities from '@/store/entities'
import { organization as schemaItem, organizations as schemaItems } from '@/config/schemas'

export class Request {
  // méthode constructor
  constructor(context, props) {
    this.context = context
    this.props = props
    const {
      name, type, docId, data, force = false, queries,
    } = props
    this.name = name
    this.type = type
    this.docId = docId
    this.data = data
    this.force = force
    this.queries = queries

    if (this.type === undefined) {
      throw new Error('type must be defined')
    }

    this.query = db.collection(this.type)
  }

  loading() {
    return this.context.dispatch('requests/set', {
      name: this.name,
      loading: true,
    }, { root: true })
  }

  loaded(data) {
    return this.context.dispatch('requests/set', {
      name: this.name,
      result: data.result,
      loaded: true,
    }, { root: true })
  }

  error(error, type) {
    console.error(`onRequest ${type} ${this.name}`, error)
    const customAttributes = {}
    // Inclure le type d'erreur
    customAttributes.errorType = error.name
    // Inclure le message d'erreur
    customAttributes.errorMessage = error.message
    // Inclure le chemin d'accès de la requête (si disponible)
    if (error.path) {
      customAttributes.requestPath = error.path
    }
    // Inclure un timestamp pour l'heure de l'erreur
    customAttributes.timestamp = new Date().toISOString()

    customAttributes.userId = this.context?.rootState?.auth.userId

    customAttributes.queries = this.queries

    const err = new Error(`[Request ${type} :: ${this.name}]`)
    // window.newrelic?.noticeError(error, { customAttribute: 'valeur' });
    window.newrelic?.noticeError(err, { freeday: customAttributes })

    return this.context.dispatch('requests/set', {
      name: this.name,
      result: [],
      loaded: true,
      errors: error,
    }, { root: true })
  }

  normalizeDatas(data, schema = schemaItem) {
    // console.log('normalizeDatas', data)
    // normalizr
    const { entities, result } = Entities({
      data,
      schema,
    })

    // console.log('normalized datas', { entities, result })

    this.loaded({ result })

    // data store saved
    const payload = {
      type: this.type,
      data: Object.values(entities)[0], // data normalizr
    }
    this.context.commit('entities/updateEntities', payload, { root: true })
  }

  requestInterval() {
    const timestamp = this.context.rootGetters['requests/timestamp']({ name: this.name })

    const interval = Math.floor((Date.now() - timestamp) / 1000) // in seconds

    return (interval > 3600) // 1H
  }

  async get() {
    // Check if request is loaded
    const isLoaded = this.context.rootGetters['requests/loaded']({ name: this.name })

    // if data stored then do nothing
    if (isLoaded && !this.requestInterval() && !this.force) return
    this.loading()

    // console.log('🔥 requestGet ', this.name)

    try {
      // await query
      let response = {}
      await this.query.doc(this.docId).get().then((doc) => {
        response = doc.data()
        response.docId = doc.id
      })
      // noralizr
      await this.normalizeDatas(response)
    } catch (error) {
      this.error(error, 'Get')
    }
  }

  async list() {
    // Check if request is loaded
    const isLoaded = this.context.rootGetters['requests/loaded']({ name: this.name })

    // if data stored then do nothing
    if (isLoaded && !this.requestInterval() && !this.force) return
    this.loading()

    // console.log('🔥 requestList ', this.name)

    if (this.queries && this.queries.where) {
      // console.log('🔥🔥 requestList ', this.queries)

      this.queries.where.forEach((q) => {
        this.query = this.query.where(q.key, q.operator, q.value)
      })
    }

    const response = []
    try {
      // await query
      await this.query.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data())
          const docData = { ...doc.data() }
          docData.docId = doc.id
          response.push(docData)
        })
      })
      // noralizr
      await this.normalizeDatas(response, schemaItems)
    } catch (error) {
      this.error(error, 'list')
    }
    // console.log('🔥🔥🔥 response', response)
  }

  async set() {
    if (this.data === undefined) {
      throw new Error('data must be defined')
    }

    // Check if request is loading
    const isLoading = this.context.rootGetters['requests/loading']({ name: this.name })
    if (isLoading) return

    this.loading()
    // console.log('🔥 requestSet ', this.name)

    const payload = {
      createAt: new Date().toISOString(),
      ...this.data,
    }

    // firestore request
    let { docId } = this
    try {
      if (this.docId) {
        this.query.doc(this.docId).set(payload, { merge: true })
      } else {
        await this.query.add(payload)
          .then((docRef) => {
            docId = docRef.id
          })
      }

      // normalizr data
      this.normalizeDatas(
        {
          docId,
          ...this.data,
        },
      )
    } catch (error) {
      this.error(error, 'Set')
    }
  }

  async update() {
    if (this.data === undefined && this.docId === undefined) {
      throw new Error('docId and data must be defined')
    }

    // Check if request is loading
    const isLoading = this.context.rootGetters['requests/loading']({ name: this.name })
    if (isLoading) return

    this.loading()
    // console.log('🔥 requestUpdate ', this.name)

    try {
      if (Array.isArray(this.data)) {
        await this.query.doc(this.docId).update({
          users: firebase.firestore.FieldValue.arrayUnion(this.data[0]),
        })

        // no normalizr because it fail
        // must fetch new data from firestore
      } else {
        await this.query.doc(this.docId).set(this.data, {
          merge: true,
        })

        // normalizr data
        this.normalizeDatas(
          {
            docId: this.docId,
            ...this.data,
          },
        )
      }
    } catch (error) {
      this.error(error, 'Update')
    }
  }

  async delete() {
    if (this.docId === undefined) {
      throw new Error('docId must be defined')
    }

    // Check if request is loading
    const isLoading = this.context.rootGetters['requests/loading']({ name: this.name })
    if (isLoading) return

    this.loading()
    // console.log('🔥 requestDelete ', this.name)

    try {
      await this.query.doc(this.docId).delete()

      this.context.commit('entities/deleteEntity', {
        type: this.type,
        docId: this.docId,
      }, { root: true })
    } catch (error) {
      this.error(error, 'Delete')
    }
  }
}

