const { VUE_APP_I18N_SUPPORTED_LOCALES } = process.env

const dateTimeFormat = {
  digits: { // 09/07/2018
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  month_year: { // juillet 2018
    year: 'numeric',
    month: 'long',
  },
  month_short_year: { // Jui 2018
    year: 'numeric',
    month: 'short',
  },
  day_month: { // lundi 9 juillet
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  },
  short: { // 09 juil. 2018
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  },
  mid_short: {
    year: '2-digit',
    month: 'short',
    day: 'numeric',
  },
  shorter: { // 9 juil.
    month: 'short',
    day: 'numeric',
  },
  monthYear: { // 09/2020
    year: 'numeric',
    month: '2-digit',
  },
  year: {
    year: 'numeric',
  }, // 2018
  month: {
    month: 'short',
  }, // juil.
  month_long: {
    month: 'long',
  }, // juillet
  day: {
    day: 'numeric',
  }, // 9
  weekday: {
    weekday: 'short',
  }, // lun
  weekday_short: {
    weekday: 'narrow',
  }, // L
  weekday_long: {
    weekday: 'long',
  }, // lundi
  long: { // lun. 9 juillet 2018
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'short',
  },
  mid: { // 9 juillet 2019 à 14:00
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  full: { // lun. 9 juillet 2018 à 14:00
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
  },
  hm: { // 14:00
    hour: 'numeric',
    minute: 'numeric',
  },
}

export const dateTimeFormats = VUE_APP_I18N_SUPPORTED_LOCALES.split(',').reduce((list, locale) => {
  list[locale] = dateTimeFormat
  return list
}, {})

// We don't set a default currency: each call must specify its currency.
const numberFormat = {
  currency: {
    style: 'currency',
  },
}

export const numberFormats = VUE_APP_I18N_SUPPORTED_LOCALES.split(',').reduce((list, locale) => {
  list[locale] = numberFormat
  return list
}, {})
