<template>
  <div :class="$style.footer">
    {{ content }}
  </div>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: {
    version: {
      type: String,
      default: 'development',
    },
  },
  computed: {
    content() {
      const date = new Date()
      return `${process.env.VUE_APP_NAME}@${date.getFullYear()} powered by yuzupie - ${
        this.version
      }`
    },
  },
}
</script>

<style lang="scss" module>
.footer {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
</style>
