import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const entities = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
export default entities
