<template>
  <Calendar/>
</template>

<script>
import Calendar from '@/views/Calendar.vue'

export default {
  name: 'HomeContractor',
  components: {
    Calendar,
  },
}
</script>

<style lang="scss" module>
</style>
