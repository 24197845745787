import auth from './store'

const AuthPlugin = {}

AuthPlugin.install = (Vue, options) => {
  const { store } = options

  if (!store) throw new Error('VueX Store instance is required to initialize AuthPlugin')

  // Register VueX
  // store.registerModule('auth', router ? authWithRouter(router) : auth)
  store.registerModule('auth', auth)

  // 1. ajouter une méthode globale ou une propriété
  // Vue.myGlobalMethod = function () {
  //   // de la logique de code...
  // }

  // 2. ajouter une ressource globale
  // Vue.directive('my-directive', {
  //   bind(el, binding, vnode, oldVnode) {
  //     // de la logique de code...
  //   }
  //   ...
  // })

  // 3. injecter des options de composant
  // Vue.mixin({
  //   created() {
  //     // de la logique de code...
  //     console.log('from plugin')
  //   },
  // })

  // 4. ajouter une méthode d'instance
  Vue.prototype.$user = store.getters['auth/$user']
}

export default AuthPlugin
