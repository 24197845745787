/* eslint-disable no-tabs, import/no-named-as-default */

const date = new Date()
const fullyear = date.getFullYear()

const tpl = ({
  //   pageTitle,
  //   siteName,
  mailTitle,
  mainContent,
}) => {
  const content = `<style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
        }
        .container {
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
            background-color: #ffffff;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
        }
        h1 {
            color: #333333;
        }
        p {
            color: #666666;
        }
        a {
            color: #007bff;
            text-decoration: none;
        }
        @media only screen and (max-width: 600px) {
            .container {
                width: 100%;
            }
        }
    </style>

<div class="container">
    <h1>FreeDay: ${mailTitle}</h1>
    <hr/>
    <div>
		${mainContent}
	</div>
    <div style="text-align: center;">
        <a href="https://www.freeday.app" target="_blank">Aller sur FreeDay</a>
    </div>
    <div style="color: #d5d5d5; text-align: center; font-size: 15px;">
        Copyright &copy; ${fullyear}. <br />All rights reserved.
    </div>
</div>`

  return content
}

export default tpl
