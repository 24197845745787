import { signOut } from '@/firebase/authenticate'

const Signout = {
  async created() {
    // delete all requests
    await this.$store.dispatch('requests/delAll')
    // delete all entities
    this.$store.state.entities = {}
    await signOut()
    // this.$router.push({ name: 'login' })
  },
  render() {
    return {}
  },
}

export default Signout
