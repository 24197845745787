<template>
    <ValidationObserver
    ref="form"
    v-slot="{ invalid}"
  >
    <VForm>
      <ValidationProvider
        v-slot="v"
        rules="required|min:3"
        name="Skill"
      >
        <v-text-field
          v-model=label
          :label="($t('skills.create.label'))"
          outlined
          :error-messages="v.errors[0]"
        />
      </ValidationProvider>

      <v-btn
        :disabled="invalid"
        color="success"
        @click="onClick"
        block
        class="mr-2 my-4"
      >
        {{ $t('global.valid')}}
      </v-btn>
    </VForm>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  VForm,
  VTextField,
  VBtn,
} from 'vuetify/lib'

export default {
  name: 'SkillsCreate',
  props: {

  },
  components: {
    VForm,
    VTextField,
    VBtn,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      label: null,
    }
  },
  methods: {
    onClick() {
      const { docId, person: { email } } = this.$user.get()
      return this.$store.dispatch('entities/set', {
        name: 'create-skills',
        type: 'skills',
        data: {
          user: {
            docId,
            email,
          },
          skill: {
            label: this.label,
          },
        },
      }).then(() => {
        this.label = ''
        this.$refs.form.reset()
      })
    },
  },
}
</script>

<style lang="scss"  module>

</style>
