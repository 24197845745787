const userMixin = {
  computed: {
    role() {
      // if (this.user.role) {
      //   return Object.keys(this.user?.role).join(' ')
      // }
      return this.user?.role
    },
    username() {
      return this.user?.person?.displayName || this.user?.person?.email
    },
    user() {
      const user = this.$user.get()
      return user
    },
  },
}

export default userMixin
