<template>
  <div>
    <UsersList />
    <EventsListEmail/>
  </div>
</template>

<script>
import UsersList from '@/views/UsersList.vue'
import EventsListEmail from '@/views/EventsListEmail.vue'

export default {
  name: 'HomeContractor',
  components: {
    UsersList,
    EventsListEmail,
  },
}
</script>

<style lang="scss" module>
</style>
