import { extend, configure } from 'vee-validate'
import {
  required, email, min, numeric,
} from 'vee-validate/dist/rules'
import i18n from '../i18n'

// branche les messages sur vue-i18n
configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`) // eslint-disable-line no-underscore-dangle
    return i18n.t(`veevalidate.errors.${values._rule_}`, values) // eslint-disable-line no-underscore-dangle
  },
})

// No message specified.
extend('email', email)
extend('min', min)
extend('numeric', numeric)
extend('required', required)

// Override the default message.
// extend('required', {
//   ...required,
//   message: 'veevalidate.errors.required', // i18n key
// })

extend('password', (value) => {
  // password Maj, min, number, len 8
  // const regex = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})', 'g')

  const regex = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])', 'g') // eslint-disable-line prefer-regex-literals
  if (regex.test(value)) {
    return true
  }
  return false
})
