<template>
  <div>
    <VDataTable
        :headers="headers"
        :items="items"
        :search="search"

        class="elevation-1"
      >

      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="red darken-2"
        >
          <v-icon
            small
            dark
            @click="onClick(item)"
          >
            fa fa-times
          </v-icon>

        </v-btn>
      </template>
    </VDataTable>

    <v-dialog
        v-model="dialog"
        max-width="290"
      >
      <v-card>
        <v-card-title class="headline">{{ $t('skills.deleteAlert.head') }}</v-card-title>

        <v-card-text>
          {{ $t('skills.deleteAlert.text') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            {{ $t('global.cancel') }}
          </v-btn>

          <v-btn
            color="red"
            dark
            @click="deleteAction"
          >
            {{ $t('global.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  VDataTable,
  VDialog,
} from 'vuetify/lib'

export default {
  name: 'SkillList',
  props: {
    docId: String,
  },
  components: {
    VDataTable,
    VDialog,
  },
  data() {
    return {
      dialog: false,
      search: '',
      id: null,
      headers: [
        {
          text: this.$t('skills.create.label'),
          align: 'start',
          sortable: false,
          value: 'skill.label',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],

    }
  },
  computed: {
    items() {
      const res = this.$store.getters['entities/list']({
        type: 'skills',
      })
      return [...res]
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    onClick(item) {
      this.dialog = true
      this.id = item.docId
    },
    async deleteAction() {
      await this.$store.dispatch('entities/del', {
        name: `del-skill-${this.id}`,
        type: 'skills',
        docId: this.id,
      })
    },
    async fetch() {
      const where = [
        {
          key: 'user.docId',
          operator: '==',
          value: this.docId,
        },
      ]

      await this.$store.dispatch('entities/list', {
        name: 'fetch-skills',
        type: 'skills',
        queries: {
          where,
        },
      })
    },
  },
}
</script>

<style lang="scss"  module>

</style>
