<template>
  <VCard
    class="mx-auto mt-8"
    max-width="1200px"
    outlined
  >
    <VCardTitle>{{ $t('organizationList.pageTitle') }}</VCardTitle>
    <VCardText>
      <OrganizationsProvider>
        <div slot-scope="{ data, loading }">
          <div v-if="loading">{{$t('global.loading')}}</div>

          <div v-else>
            <Organizations :items="data"/>
          </div>
        </div>
      </OrganizationsProvider>

    </VCardText>
  </VCard>
</template>

<script>
import { VCard, VCardTitle, VCardText } from 'vuetify/lib'
import OrganizationsProvider from '@/modules/Organizations/OrganizationsProvider'
import Organizations from '@/modules/Organizations/Organizations.vue'
import {logEvent} from '@/utils/logEvent'

export default {
  name: 'UsersList',
  components: {
    VCard,
    VCardTitle,
    VCardText,
    OrganizationsProvider,
    Organizations,
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - OrganizationsList',
  },
  created() {
    // Record custom event to analytics "organizationsListPage"
    logEvent('organizationsListPage')
  },
}
</script>

<style lang="scss" module>

</style>
