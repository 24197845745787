<template>
  <div>
    <v-date-picker
      v-model="dates"
      multiple
      no-title
      :events="arrayEvents"
      :event-color="colorsEvents"
      :allowed-dates="allowedDates"
      :first-day-of-week="1"
      locale="fr"
      color="primary"
    >
    </v-date-picker>

    <v-autocomplete
      v-model="type"
      :items="types"
      hide-no-data
      hide-selected
      item-text="Habilitation"
      item-value="API"
      :label="$t('datepicker.formationsAvailable')"
      :placeholder="$t('global.typingForSearch')"
      return-object
    />

    <v-text-field
      v-model="eventName"
      :label="$t('datepicker.eventName')"
    />

    <v-checkbox
      v-model="validByClient"
      :label="$t('calendar.valid_by_client')"
      color="primary"
      hide-details
    ></v-checkbox>

    <v-textarea
      :label="$t('datepicker.comment')"
      rows="1"
      value=""
      @change="onChange"
    ></v-textarea>

    <v-text-field
      type="number"
      v-model="cost"
      :label="$t('datepicker.cost')"
      append-icon="fa fa-euro-sign"
    />

    <div class='text-center'>
      <v-btn
        color="primary"
        :disabled="disabled"
        @click="onSubmit"
      >
        {{ $t('global.valid') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  VTextField,
  VDatePicker,
  VTextarea,
  VBtn,
  VAutocomplete,
} from 'vuetify/lib'

import {logEvent} from '@/utils/logEvent'

import colors from '@/utils/colors'

export default {
  name: 'DatePickerMultiple',
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    contractorId: {
      type: String,
      required: true,
    },
    customer: {
      type: Object,
    },
  },
  components: {
    VDatePicker,
    VTextField,
    VTextarea,
    VBtn,
    VAutocomplete,
  },
  data() {
    return {
      picker: new Date().toISOString().substr(0, 10),
      menuMultiple: false,
      dates: [],
      eventName: '',
      validByClient: false,
      comment: '',
      cost: '',
      type: null,
    }
  },
  computed: {
    contractor() {
      return this.$store.getters['entities/get']({
        type: 'users',
        key: this.contractorId,
      })
    },
    types() {
      const result = this.$store.getters['requests/result']({
        name: `fetch-skills-${this.contractorId}`,
      })

      const skills = result.reduce((accu, curr) => {
        const { skill } = this.$store.getters['entities/get']({
          type: 'skills',
          key: curr,
        })
        accu.push(skill.label)
        return accu
      }, [])
      return skills || []
    },
    disabled() {
      return (
        !this.contractorId
        || !this.type
        || !this.comment
        || this.dates.length === 0
      )
    },
    colorsEvents() {
      return (date) => {
        const result = this.events.reduce((acc, cur) => {
          if (cur.contractor.docId === this.contractorId && cur.start.substr(0, 10) === date) {
            acc.push(cur.color)
          }
          return acc
        }, [])
        return result
      }
    },
    arrayEvents() {
      const result = this.events.reduce((acc, cur) => {
        if (cur.contractor.docId === this.contractorId && cur.status === 'fulfilled') {
          acc.push(cur.start.substr(0, 10))
        }
        return acc
      }, [])

      return result
    },
    baseEvents() {
      return this.$store.getters['entities/get']({
        key: this.contractorId,
        type: 'baseEvents',
      }) || {}
    },
    allDatesEvent() {
      const events = this.$store.getters['entities/list']({
        type: 'events',
      }) || {}
      const result = events.reduce((acc, cur) => {
        const date = `${cur.date.year}-${cur.date.month}-${cur.date.day}`
        acc.push(date)
        return acc
      }, [])
      return result
    },
  },
  created() {
    this.init()
  },
  watch: {
    contractorId(val, oldval) {
      if (val !== oldval) {
        this.init()
      }
    },
  },
  methods: {
    async init() {
      const where = [
        {
          key: 'contractor.docId',
          operator: '==',
          value: this.contractorId,
        },
      ]

      // fetch baseEvents
      await this.$store.dispatch('entities/get', {
        name: `fetch-base-events-${this.contractorId}`,
        type: 'baseEvents',
        docId: this.contractorId,
        queries: {
          where,
        },
      })

      // fetch contractor's skills
      await this.$store.dispatch('entities/list', {
        name: `fetch-skills-${this.contractorId}`,
        type: 'skills',
        queries: {
          where: [
            {
              key: 'user.docId',
              operator: '==',
              value: this.contractorId,
            },
          ],
        },
      })
    },
    onChange(value) {
      this.comment = value
    },
    allowedDates(val) {
      // wait for baseEvents load datas
      // if (this.baseEvents.dates === undefined) return false

      // disable weekend date / firstDay and event day
      const firstDay = (new Date(val) > new Date(this.picker))
      const weekendDay = ![0, 6].includes(new Date(val).getDay())

      // return false if event status fulfilled exist
      const eventDay = !this.arrayEvents.includes(val)

      // return false if val exist into baseEvents dates
      const notAlloyDay = (this.baseEvents.dates === undefined) || (!this.baseEvents.dates.includes(val)) // eslint-disable-line
      return firstDay && weekendDay && eventDay && notAlloyDay
    },
    async onSubmit() {
      this.dates.forEach((date) => {
        if (this.allDatesEvent.includes(date)) {
          // check if the date is already dateAlreadySubmited
          // ask for confirmation OK add date, cancel don't
          const formattedDate = this.$d(new Date(date), 'digits')
          const msg = this.$t('datepicker.dateAlreadySubmited', { date: formattedDate })
          // const isOK = window.confirm(msg)
          // // true if OK is presse
          // if (isOK) {
          //   this.saveNewEvent(date)
          // }
          // eslint-disable-next-line no-alert
          alert(msg)
          return
        }
        this.saveNewEvent(date)
      })

      // Record custom event to analytics "newEvent"
      logEvent('newEvent')

      return true
    },
    async saveNewEvent(date) {
      const { docId, person, organization } = this.customer || this.$user.get()

      const d = new Date(date)

      const event = {
        date: {
          year: d.getFullYear(),
          month: d.getMonth() + 1, // january = 0
          day: d.getDate(),
        },
        name: this.eventName,
        start: date,
        end: date,
        status: 'pending',
        type: this.type,
        color: colors.pending,
        user: {
          docId,
          email: person.email,
          fullName: (person.firstName || person.lastName)
            ? `${person.firstName} ${person.lastName}`
            : person.email,
        },
        organization,
        contractor: {
          docId: this.contractorId,
          email: this.contractor?.person.email,
          fullName: (this.contractor?.person.firstName || this.contractor?.person.lastName)
            ? `${this.contractor.person.firstName} ${this.contractor.person.lastName}`
            : this.contractor.person.email,
        },
        cost: this.cost,
        comment: this.comment,
        validByClient: this.validByClient,
      }

      await this.$store.dispatch('entities/set', {
        name: `new-event-${date}`,
        // type: 'events-multiple',
        type: 'events',
        data: event,
      })

      console.log('send', {
        name: `new-event-${date}`,
        type: 'events',
        data: event,
      })

      this.sendEmail(event)
      this.dates = []
      this.type = null
    },
    async sendEmail(event) {
      event.date = this.$d(new Date(event.start), 'digits')
      // todo replace by store action
      try {
        // console.log('send email', event)
        this.$store.dispatch('mail/newEvent', { event })
      } catch (e) {
        console.log('sendEmail error', e)
        const err = new Error('[DatePickerMultiple] sendEmail', e)
        window.newrelic.noticeError(err)
      }
    },
  },
}
</script>

