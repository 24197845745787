const EventsProvider = {
  created() {
    this.loadData()
  },
  computed: {
    data() {
      const users = this.$store.getters['entities/list']({
        type: 'users',
      })

      return users
    },
    loading() {
      return this.$store.getters['requests/loading']({
        name: 'users-get',
      })
    },
  },
  methods: {
    loadData() {
      return this.$store.dispatch('entities/list', {
        name: 'users-get',
        type: 'users',
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      loading: this.loading,
      data: this.data,
    })
  },
}

export default EventsProvider
