<template>
  <VCard
    class="mx-auto mt-8"
    max-width="1200px"
    outlined
  >
    <VCardTitle>{{ $t('skills.pageTitle')}}</VCardTitle>
    <VCardText v-if="userId">
      <div :class="$style.grid">
        <div>
          {{ $t('skills.skillsList')}}
          <SkillList :docId="userId" />
        </div>

        <div>
          {{ $t('skills.addNewSkill')}}
          <SkillsCreate/>
        </div>
      </div>
    </VCardText>
  </VCard>
</template>

<script>
import {
  VCard, VCardTitle, VCardText,
} from 'vuetify/lib'
import SkillsCreate from '@/modules/Skills/SkillsCreate.vue'
import SkillList from '@/modules/Skills/SkillsList.vue'
import {logEvent} from '@/utils/logEvent'

export default {
  name: 'SkillComponent',
  props: {

  },
  components: {
    VCard,
    VCardTitle,
    VCardText,
    SkillsCreate,
    SkillList,
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - Skills',
  },
  computed: {
    userId() {
      return this.$user.get()?.docId
    },
  },
  created() {
    // Record custom event to analytics "skillsPage"
    logEvent('skillsPage')
  },
}
</script>

<style lang="scss" module>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 20px;
}
</style>
