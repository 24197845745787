const EventsProvider = {
  created() {
    this.loadData()
  },
  computed: {
    data() {
      const events = this.$store.getters['entities/list']({
        type: 'users',
      })
      const res = [...events.filter((user) => user.role?.contractor === true)]
      return res
    },
    items() {
      const reducer = (accu, curr) => {
        const fullName = `${curr.person.firstName} ${curr.person.lastName}`
        const user = {
          text: (fullName.length > 1) ? fullName : curr.person.email,
          value: curr.docId,
        }
        accu.push(user)
        return accu
      }

      return this.data.reduce(reducer, []) || []
    },
    loading() {
      return this.$store.getters['requests/loading']({
        name: 'fetch-contractor',
      })
    },
  },
  methods: {
    loadData() {
      return this.$store.dispatch('entities/list', {
        name: 'fetch-contractor',
        type: 'users',
        queries: {
          where: [
            {
              key: 'role.contractor',
              operator: '==',
              value: true,
            },
          ],
        },
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      loading: this.loading,
      items: this.items,
    })
  },
}

export default EventsProvider
