// import Vue from 'vue'

const set = (context, data) => {
  context.commit('set', data)
}

const del = (context, data) => {
  console.log('delete', context, data)
}

export default {
  del,
  set,
}
