<template>
  <div>
    <v-autocomplete
      v-bind="$attrs"
      v-on="$listeners"
      :items="items"
      color="white"
      :value="defaultValue"
      hide-no-data
      hide-selected
      :label="label"
      :placeholder="$t('global.typingForSearch')"
    />
  </div>
</template>

<script>
import {
  // VMenu,
  VAutocomplete,
} from 'vuetify/lib'

export default {
  name: 'SelectComponent',
  components: {
    VAutocomplete
    ,
  },
  props: {
    value: {
      type: [String, null],
    },
    items: {
      type: Array,
    },
  },
  computed: {
    defaultValue() {
      if (this.items.length === 1) {
        this.$emit('input', this.items[0].value)
        return this.items[0]
      }
      return ''
    },
    label() {
      return `${this.$t('contractorSelector.chooseYourContractor')} (${this.items.length})`
    },
  },
}
</script>

<style lang="scss" module>

</style>
