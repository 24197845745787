<template>
  <VCard
    class="mx-auto mt-8"
    max-width="1200px"
    outlined
  >

    <VCardTitle>{{ $t('organization.pageTitle2')}}</VCardTitle>

    <VCardText>
      <p>{{ $t('organization.baseline')}}</p>

      <UsersProvider v-if="organizationId" :organizationId="organizationId">
        <div slot-scope="{ data, loading }">
          <div v-if="loading">{{$t('global.loading')}}</div>

          <div v-else :class="$style.grid">
            <OrganizationListUsers :users="data"/>
            <OrganizationAddUser
              :organizationId="organizationId"
              :organizationName="organizationName"
            />
          </div>
        </div>
      </UsersProvider>
    </VCardText>
  </VCard>
</template>

<script>
import OrganizationAddUser from '@/modules/Organization/OrganizationAddUser.vue'
import OrganizationListUsers from '@/modules/Organization/OrganizationListUsers.vue'
import {
  VCard, VCardTitle, VCardText,
} from 'vuetify/lib'
import userMixin from '@/components/mixins'
import UsersProvider from '@/modules/Organization/UsersProvider'
import {logEvent} from '@/utils/logEvent'

export default {
  name: 'OrganizationUsersList',
  components: {
    OrganizationAddUser,
    OrganizationListUsers,
    UsersProvider,
    VCard,
    VCardTitle,
    VCardText,
  },
  mixins: [userMixin],
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - OrganizationUsersList',
  },

  computed: {
    organizationId() {
      const user = this.$user.get()
      return user?.organization?.docId
    },
    organizationName() {
      const user = this.$user.get()
      return user?.organization?.name
    },
  },
  created() {
    // Record custom event to analytics "organizationUsersListPage"
    logEvent('organizationUsersListPage')
  },
}
</script>

<style lang="scss" module>
.grid {
  display: grid;
  grid-template-columns:  4fr 1fr;
  grid-gap: 20px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

</style>
