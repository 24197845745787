import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/firebase'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // route guard
  auth.onAuthStateChanged((user) => {
    // can access to login and forgotten when you are not logged
    const regex = /(login|forgotten)/g

    if (user) {
      auth
        .currentUser.getIdTokenResult()
        // .then((tokenResult) => {
        //   console.log('claims from router', tokenResult.claims)

        //   return tokenResult
        // })
        .then((tokenResult) => tokenResult)
    }

    if (user === null && !to.name.match(regex)) {
      next({ name: 'login' })
    } else {
      next()
    }
  })
})

export default router
