import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/i18n/locales'

import { dateTimeFormats, numberFormats } from './formats'

Vue.use(VueI18n)

const {
  VUE_APP_I18N_DEFAULT_LOCALE,
  VUE_APP_I18N_FALLBACK_LOCALE,
} = process.env

const i18n = new VueI18n({
  locale: VUE_APP_I18N_DEFAULT_LOCALE,
  fallbackLocale: VUE_APP_I18N_FALLBACK_LOCALE,
  messages,
  // messages: { fr: messagesFr, en: messagesEn },
  dateTimeFormats,
  numberFormats,
  missing: (locale, id) => (`__MISS_${id}`),
  silentTranslationWarn: true,
})
export default i18n

