<template>
  <ValidationObserver
    ref="form"
    v-slot="{ invalid }"
  >
    <VForm>
      <div :class="$style.grid">
        <ValidationProvider
          v-slot="v"
          rules="min:3"
          name="Organization"
        >
          <v-text-field
            v-model="form.organization"
            :label="($t('profile.organization'))"
            outlined
            :error-messages="v.errors[0]"
            :disabled="true"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="v"
          rules="min:3"
          name="Displayname"
        >
          <v-text-field
            v-model="form.displayName"
            :label="($t('profile.displayname'))"
            outlined
            :error-messages="v.errors[0]"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="v"
          name="Firstname|min:3"
        >
          <v-text-field
            v-model="form.firstName"
            :label="($t('profile.firstname') + '*')"
            outlined
            :error-messages="v.errors[0]"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="v"
          name="Lastname|min:3"
        >
          <v-text-field
            v-model="form.lastName"
            :label="($t('profile.lastname') + '*')"
            outlined
            :error-messages="v.errors[0]"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="v"
          rules="email"
          name="Email"
        >
          <v-text-field
            v-model="form.email"
            :label="($t('profile.email') + '*')"
            outlined
            :error-messages="v.errors[0]"
            :disabled="true"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="v"
          name="Phonenumber"
          rules="numeric"
        >
          <v-text-field
            v-model="form.phoneNumber"
            :label="($t('profile.phonenumber') + '*')"
            outlined
            :error-messages="v.errors[0]"
            type="tel"
          />
        </ValidationProvider>

        <v-switch
          color="primary"
          value
          inset
          v-model="form.role.admin"
          label="admin"
        ></v-switch>

        <v-switch
          color="primary"
          value
          inset
          v-model="form.role.customer"
          label="customer"
        ></v-switch>

        <v-switch
          color="primary"
          value
          inset
          v-model="form.role.contractor"
          label="contractor"
        ></v-switch>
      </div>

      <v-btn
        :disabled="invalid"
        color="success"
        @click="onClick"
        block
        class="mr-2 my-4"
      >
        {{ $t('global.valid')}}
      </v-btn>
    </VForm>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  VForm,
  VTextField,
  VBtn,
  VSwitch,
} from 'vuetify/lib'

export default {
  name: 'UserEdit',
  components: {
    VForm,
    VTextField,
    VBtn,
    ValidationProvider,
    ValidationObserver,
    VSwitch,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        organization: this.user?.organization?.name,
        displayName: this.user?.person?.displayName,
        email: this.user?.person?.email,
        firstName: this.user?.person?.firstName,
        lastName: this.user?.person?.lastName,
        phoneNumber: this.user?.person?.phoneNumber,
        role: this.user?.role,
      },
    }
  },
  methods: {
    async onClick() {
      const {
        displayName, email, firstName, lastName, phoneNumber, role,
      } = this.form
      await this.$store.dispatch('entities/update', {
        name: 'currentUser-update',
        type: 'users',
        docId: this.user?.docId,
        data: {
          person: {
            displayName,
            email,
            firstName,
            lastName,
            phoneNumber,
          },
          role,
        },
      })
    },
  },
}
</script>

<style lang="scss" module>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 20px;
}
</style>
