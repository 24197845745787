<template>
  <div>
    storage
    <pre>
      {{ medias }}
    </pre>

    <img
      :src="media"
      v-for="media in medias"
      :key="media"
      :class='$style.media'
      alt=""
    />
  </div>
</template>

<script>
import { storage } from '@/firebase'

export default {
  name: 'StorageComponent',
  components: {
  },
  props: {
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - Storage',
  },
  data: () => ({
    medias: [],
  }),
  computed: {
  },
  created() {
    // Create a storage reference from our storage service
    const storageRef = storage.ref()
    // Create a child reference
    const imagesRef = storageRef.child('images')

    // list file
    // Create a reference under which you want to list

    // Find all items.
    imagesRef.listAll().then((res) => {
      res.items.forEach((itemRef) => {
        // All the items under listRef.
        // console.log('path', `${itemRef.location.bucket}/${itemRef.location.path}`)
        this.getDownloadURL(storageRef, itemRef.location.path)
      })
    }).catch((error) => {
      // Uh-oh, an error occurred!
      console.error('error', error)

      const err = new Error('[Storage] listAll', error)
      window.newrelic.noticeError(err)
    })
  },
  methods: {
    // https://firebase.google.com/docs/storage/web/download-files
    getDownloadURL(storageRef, refPath) {
      storageRef.child(refPath).getDownloadURL().then((url) => {
        // console.log('🚀 🚀 🚀 🚀 🚀 🚀 🚀   url', url)
        this.medias.push(url)
      })
    },
  },
}
</script>

<style lang="scss" module>
.media {
  width: 250px;
  height: auto;
}
</style>
