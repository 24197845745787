<template>
  <div>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('global.search')"
          single-line
          hide-details
        ></v-text-field>

        <v-btn outlined class="mr-4" color="grey darken-2" @click="dataFetch"> Refresh </v-btn>
      </v-card-title>
      <VDataTable
        :headers="headers"
        :items="mails"
        :search="search"
        :items-per-page="10"
        :sort-by="['name', 'user', 'organization', 'date', 'type', 'status']"
        class="elevation-1"
      >
        <template v-slot:item.createAt="{ item }">
          {{ $d(new Date(item.createAt), 'digits') }}
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip :color="item.color" dark>{{ item.status }}</v-chip>
        </template>

        <template v-slot:item.start="{ item }">
          {{ $d(new Date(item.start), 'digits') }}
        </template>

        <template v-slot:item.actions="{ item }">
          <!-- v-if="(item.status === 'fulfilled')" -->
          <v-btn v-if="item.delivery.state === 'ERROR'" class="mx-2" fab dark x-small color="green">
            <v-icon small dark @click="retry(item)"> fa fa-plus </v-icon>
          </v-btn>
        </template>
      </VDataTable>
    </v-card>
  </div>
</template>

<script>
import {
  VBtn, VDataTable, VCard, VCardTitle, VSpacer, VTextField,
} from 'vuetify/lib'

// import colors from '@/utils/colors'

export default {
  name: 'EventsList',
  components: {
    VBtn,
    VDataTable,
    VCard,
    VCardTitle,
    VSpacer,
    VTextField,
  },
  props: {},
  data() {
    return {
      dialog: false,
      docId: null,
      search: '',
      headers: [
        {
          text: 'Destinataire',
          align: 'start',
          sortable: false,
          value: 'to',
        },
        { text: 'createAt', value: 'createAt' },
        { text: 'Sujet', value: 'message.subject' },
        { text: 'State', value: 'delivery.state' },
        { text: 'Error', value: 'delivery.error' },
        { text: 'Retry', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {
    mails() {
      const res = this.$store.getters['entities/list']({
        type: 'mail',
      })
      return res
    },
  },
  created() {
    this.dataFetch()
  },
  methods: {
    async dataFetch() {
      await this.$store.dispatch('entities/list', {
        name: 'all-mails',
        type: 'mail',
        force: true,
        queries: {
          where: [
            {
              key: 'delivery.state',
              operator: '==',
              value: 'ERROR',
            },
          ],
        },
      })
    },
    async retry(item) {
      const newEmail = { ...item, ...{ delivery: { state: 'PENDING' } } }

      await this.$store.dispatch('entities/update', {
        name: `mail-update-${item.docId}`,
        force: true,
        type: 'mail',
        docId: item.docId,
        data: newEmail,
      })
    },
  },
}
</script>
