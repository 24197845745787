import { analytics } from '@/firebase'

export const logEvent = (name, options) => {
  try {
    if (process.env.VUE_APP_MODE === 'production' && analytics) {
      console.log(`analytics logEvent ${name}`, options)
      analytics.logEvent(name, options)
    }
  } catch (e) {
    console.error(`analytics logEvent ${name}`, e)
    const err = new Error(`[Event ${name}]`)
    window.newrelic.noticeError(err, { analytics: e })
  }
}
