/**
 * Get all entities of `type` as an object (indexed by key)
 * const figurinesAll = await this.$store.getters['entities/all']({type: 'figurines'})
 *
 * @param {Object} props
 * @param {String} props.type    - Entity name in store (ex: "users")
 * @return {Object<Object>}
 */
const all = (state) => (props) => {
  const { type } = props
  return state[type] || {}
}

/**
 * Get all entities of `type` as an array
 * const figurinesAll = await this.$store.getters['entities/list']({type: 'figurines'})
 *
 * @param {Object} props
 * @param {String} props.type    - Entity name in store (ex: "users")
 * @param {String} props.filter   - A predicate to execute on result
 * @return {Array<Object>}
 */
const list = (state) => (props) => {
  const { type, filter } = props
  return filter
    ? Object.values(all(state)({ type })).filter(filter)
    : Object.values(all(state)({ type }))
}

/**
 * Get the entity of `type`at `key`
 * this.$store.getters['entities/get']({ type: 'figurines', key: '0bVFOZhywWGYFN1hfjTe' })
 *
 * @param {Object} props
 * @param {String} props.type    - Entity name in store (ex: "users")
 * @param {String} props.key    - Entity entry unique key
 * @return {ObjectUnsubscribedError}
 */
const get = (state) => (props) => {
  const { type, key } = props
  return all(state)({ type })[key]
}

export default {
  all,
  list,
  get,
}
