import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import fr from 'vuetify/src/locale/fr.ts'

Vue.use(Vuetify)

// localStorage.setItem('dark', 'true
// console.log('prefersDarkScheme', prefersDarkScheme.matches)
// get the default OS scheme
const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)')
// use default os theme OR localStorage value OR false
const prefersDarkSchemeLocal = window.localStorage.getItem('dark')

const dark = prefersDarkScheme.matches || prefersDarkSchemeLocal !== 'false' || false

const opts = {
  theme: {
    dark,
    themes: {
      light: {
        // primary: '#3f51b5',
        // secondary: '#b0bec5',
        // accent: '#8c9eff',
        // error: '#b71c1c',
      },
      dark: {
        // primary: '#3f51b5',
        // secondary: '#b0bec5',
        // accent: '#8c9eff',
        // error: '#b71c1c',
      },
    },
  },
  icons: {
    iconfont: 'fa',
  },
  lang: {
    locales: { fr },
    current: 'fr',
  },
}

export default new Vuetify(opts)
