<template>
  <VCard
    class="mx-auto mt-8"
    max-width="1200px"
    outlined
  >
    <VCardTitle>{{ $t('organization.pageTitle')}}</VCardTitle>

    <VCardText>
      <OrganizationCreate  />
    </VCardText>
  </VCard>
</template>

<script>
import OrganizationCreate from '@/modules/Organization/OrganizationCreate.vue'
import {
  VCard, VCardTitle, VCardText,
} from 'vuetify/lib'
import userMixin from '@/components/mixins'
import {logEvent} from '@/utils/logEvent'

export default {
  name: 'OrganizationComponent',
  components: {
    OrganizationCreate,
    VCard,
    VCardTitle,
    VCardText,
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - Organization',
  },
  mixins: [userMixin],
  created() {
    // Record custom event to analytics "organizationPage"
    logEvent('organizationPage')
  },
}
</script>

<style lang="scss" module>
.grid {
  display: grid;
  grid-template-columns:  4fr 1fr;
  grid-gap: 20px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

</style>
