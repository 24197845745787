<template>
  <div>
    <div>{{ $t('organizationAddUser.title') }}</div>
    <ValidationObserver
      ref="form"
      v-slot="{ invalid }"
    >
      <VForm>
        <ValidationProvider
          v-slot="v"
          rules="required|email"
          name="Email"
        >
          <v-text-field
            v-model="email"
            :label="$t('organizationAddUser.email')"
            :error-messages="v.errors[0]"
            outlined
            required
          />
        </ValidationProvider>

        <v-btn
          :disabled="invalid"
          color="info"
          @click="createAccount"
          :loading="loading"
        >
          {{$t('global.valid') }}
        </v-btn>
      </VForm>
    </ValidationObserver>
  </div>
</template>

<script>
import { functions /* db */ } from '@/firebase'
// import { signUp } from '@/firebase/authenticate'
import { VForm, VTextField, VBtn } from 'vuetify/lib'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import user from '@/components/mixins'
// import { Request } from '@/firebase/request'
import Entities from '@/store/entities'
import { user as schemaItem } from '@/config/schemas'

import {logEvent} from '@/utils/logEvent'

export default {
  name: 'OrganisationAddUser',
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    organizationName: {
      type: String,
      required: true,
    },
  },
  components: {
    VForm, VTextField, VBtn, ValidationProvider, ValidationObserver,
  },
  mixins: [user],
  data() {
    return {
      email: null,
      loading: false,
    }
  },
  methods: {
    async createAccount() {
      this.loading = true
      try {
        const createUser = await functions.httpsCallable('createUser')
        createUser({
          email: this.email.toLowerCase(),
          organization: {
            name: this.organizationName,
            docId: this.organizationId,
          },
        }).then((res) => {
          // normalizr response
          const { entities /* , result */ } = Entities({
            data: res.data.payload,
            schema: schemaItem,
          })
          const payload = {
            type: 'users',
            data: Object.values(entities)[0], // data normalizr
          }

          this.$store.commit('entities/updateEntities', payload)
        }).then(() => {
          this.loading = false
        }).catch((e) => {
          this.$refs.form.setErrors({
            Email: [e.message],
          })

          this.loading = false
        })

        // Record custom event to analytics "createAccount"
        logEvent('organizationCreateAccount', {
          method: 'email',
        })
      } catch (e) {
        console.log('createUser error', e)
        this.$refs.form.setErrors({
          Email: [e.message],
        })
        this.loading = false

        const err = new Error('[OrganisationAddUser] createAccount', e)
        window.newrelic.noticeError(err)
      }
    },
  },
}
</script>

<style lang="scss" module>
.error {
  color: tomato;
}
</style>
