<template>
  <div>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('global.search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <VDataTable
        :headers="headers"
        :items="users"
        :search="search"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:item.role.admin="{ item }">
          <v-chip color="red" dark v-if="item.role.admin">{{ item.role.admin }}</v-chip>
        </template>

        <template v-slot:item.role.contractor="{ item }">
          <v-chip color="blue" dark v-if="item.role.contractor">{{ item.role.contractor }}</v-chip>
        </template>

        <template v-slot:item.role.customer="{ item }">
          <v-chip color="green" dark v-if="item.role.customer">{{ item.role.customer }}</v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn class="mx-2" fab dark x-small color="green">
            <v-icon small dark @click="onEdit(item)"> fa fa-edit </v-icon>
          </v-btn>

          <v-btn class="mx-2" fab dark x-small color="red darken-2">
            <v-icon small dark @click="onDelete(item)"> fa fa-times </v-icon>
          </v-btn>
        </template>
      </VDataTable>
    </v-card>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">{{ $t('usersList.deleteAlert.head') }}</v-card-title>

        <v-card-text>
          {{ $t('usersList.deleteAlert.text') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="dialog = false">
            {{ $t('global.cancel') }}
          </v-btn>

          <v-btn color="red" dark @click="deleteAction">
            {{ $t('global.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEdit" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialogEdit = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Admin</v-toolbar-title>
      </v-toolbar>

      <v-card>
        <v-card-title class="headline">{{ $t('userAdmin.head') }}</v-card-title>

        <v-card-text>
          {{ $t('userAdmin.text') }}
          <UserEdit :user="itemEdited" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { /* db, */ functions } from '@/firebase'
import UserEdit from '@/modules/Users/UserEdit.vue'

import {
  VDataTable,
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VBtn,
  VSpacer,
  VTextField,
} from 'vuetify/lib'

export default {
  name: 'UsersComponent',
  components: {
    VDataTable,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VBtn,
    VSpacer,
    VTextField,
    UserEdit,
  },
  props: {
    users: {
      type: Array,
    },
  },
  data: () => ({
    dialogEdit: false,
    dialog: false,
    docId: null,
    itemEdited: {},
    search: '',
    headers: [
      {
        text: 'email',
        align: 'start',
        sortable: false,
        value: 'person.email',
      },
      { text: 'firstname', value: 'person.firstName' },
      { text: 'lastname', value: 'person.lastName' },
      { text: 'disabled', value: 'disabled' },
      { text: 'admin', value: 'role.admin' },
      { text: 'contractor', value: 'role.contractor' },
      { text: 'customer', value: 'role.customer' },
      { text: 'organization', value: 'organization.name' },
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
  }),
  methods: {
    onEdit(item) {
      console.log({ item })
      this.itemEdited = item
      this.dialogEdit = true
    },
    onDelete(item) {
      this.dialog = true
      this.docId = item.docId
    },
    async deleteAction() {
      try {
        const deleteUser = functions.httpsCallable('disabledUser')
        deleteUser({ docId: this.docId }).then((res) => {
          // delete res from users entity
          this.$store.commit('entities/deleteEntity', {
            type: 'users',
            docId: res.data.docId,
          })
        })
      } catch (e) {
        console.log('deleteUser error', e)

        const err = new Error('[Users] deleteAction', e)
        window.newrelic.noticeError(err)
      }
      this.dialog = false
    },
  },
}
</script>

<style lang="scss" module></style>
