import Vue from 'vue'
import VueMeta from 'vue-meta'

import AuthPlugin from '@/plugins/auth/index'

import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'
import i18n from './i18n'
import './plugins/veeValidate'
import './registerServiceWorker'

import App from './App.vue'

// require('dotenv').config()

Vue.config.productionTip = false

Vue.use(AuthPlugin, { someOption: true, store })
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})

const vueapp = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

if (process.env.VUE_APP_REVISION) {
  console.log('APP REVISION: ', process.env.VUE_APP_REVISION)
}

if (process.env.VUE_APP_MODE === 'development') {
  window.vueapp = vueapp
}
