import Vue from 'vue'
import { merge } from 'lodash'

const updateEntity = (state, props) => {
  const { key, type, data } = props
  if (!state[type]) Vue.set(state, type, {})
  const current = state[type][key] || {}

  Vue.set(state[type], key, merge(current, data))
  // Vue.set(state, type, data)
}

const updateEntities = (state, props) => {
  const { type, data } = props

  if (data === undefined) { return }

  const arrData = Object.entries(data)
  if (!state[type]) Vue.set(state, type, {})
  arrData.forEach((item) => {
    const current = state[type][item[0]] || {}
    Vue.set(state[type], item[0], merge(current, item[1]))
  })
}

const deleteEntity = (state, props) => {
  const { type, docId } = props
  if (!(state[type] && state[type][docId])) return
  // delete state[type][docId]
  Vue.delete(state[type], docId)
}

export default {
  updateEntity,
  updateEntities,
  deleteEntity,
}
