<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
            v-if="$vuetify.breakpoint.smAndUp"
          >
            {{ $t('calendar.today') }}
          </v-btn>

          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> fa fa-angle-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> fa fa-angle-right </v-icon>
          </v-btn>

          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
      </v-sheet>

      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          locale="fr"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :event-text-color="getEventTextColor"
          :now="today"
          :type="type"
          @click:event="showEvent"
          @change="updateRange"
        />

        <!-- modal d'un event -->
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card min-width="370px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name" />
            </v-toolbar>

            <v-card-text>
              <div v-if="selectedEvent.organization">
                <strong>{{ $t('calendar.organization') }}</strong>
                {{ selectedEvent.organization.name }}
              </div>
              <div v-if="selectedEvent.user">
                <strong>{{ $t('calendar.author') }}</strong>
                {{ selectedEvent.user.fullName }}
              </div>

              <div v-if="selectedEvent.validByClient">
                <strong>{{ $t('calendar.valid_by_client') }}</strong>
                {{ selectedEvent.validByClient ? $t('global.yes') : $t('global.no') }}
              </div>

              <div v-if="selectedEvent.type">
                <strong>{{ $t('calendar.type') }}</strong>
                {{ selectedEvent.type }}
              </div>
              <div v-if="selectedEvent.contractor">
                <strong>{{ $t('calendar.contractor') }}</strong>
                {{ selectedEvent.contractor.fullName }}
              </div>
              <div v-if="selectedEvent.cost">
                <strong>{{ $t('calendar.cost') }}</strong> {{ selectedEvent.cost }}
              </div>
              <div v-if="selectedEvent.comment">
                <strong>{{ $t('calendar.comment') }}</strong>
                <p>{{ selectedEvent.comment }}</p>
              </div>
            </v-card-text>

            <template v-if="isContactor">
              <v-divider></v-divider>

              <v-card-actions class="justify-center">
                <EventActions :event="selectedEvent" :baseEvents="baseEvents" />
              </v-card-actions>
            </template>

            <template v-else>
              <v-card-actions class="justify-center">
                <v-select
                  class="mx-4"
                  :items="cancelReasons"
                  item-text="label"
                  item-value="value"
                  label="Raisons de l'annulation"
                  @change="onChangeCancelReason"
                  outlined
                  hide-details
                  return-object
                ></v-select>
              </v-card-actions>

              <v-card-actions class="justify-center">
                <v-btn color="error" @click="cancelEvent(selectedEvent)" :disabled="cancelEventDisabled">
                  {{ $t('global.cancelRequest') }}
                </v-btn>
              </v-card-actions>
            </template>
          </v-card>
        </v-menu>
      </v-sheet>

      {{ $t('calendar.notice') }}

      <v-chip class="my-2" color="primary" label>
        {{ $t('global.reserved') }}
      </v-chip>

      <v-chip class="ma-2" color="amber" text-color="black" label>
        {{ $t('global.pending') }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
import {
  VRow,
  VCol,
  VSheet,
  VToolbar,
  VToolbarTitle,
  VBtn,
  VIcon,
  VCalendar,
  VDivider,
  VSelect,
  VMenu /* VCardText, VCardActions, */,
} from 'vuetify/lib'

import {logEvent} from '@/utils/logEvent'

import EventActions from '@/components/EventActions'

export default {
  name: 'CalendardComponent',
  components: {
    VRow,
    VCol,
    VSheet,
    VToolbar,
    VToolbarTitle,
    VBtn,
    VIcon,
    VCalendar,
    VMenu,
    VDivider,
    VSelect,
    EventActions,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    focus: '',
    type: 'month', // month
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
    },
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    cancelReasons: [
      { label: 'Changement d\'horaire', value: 'changing_schedule' },
      { label: 'Annulation du client', value: 'cancel_by_client' },
      { label: 'Changement de formateur', value: 'changing_instructor' },
      { label: 'Autre', value: 'other' },
    ],
    cancelReason: {},
  }),
  computed: {
    baseEvents() {
      return (
        this.$store.getters['entities/get']({
          key: this.contractorId,
          type: 'baseEvents',
        }) || {}
      )
    },
    contractorId() {
      return this.$user.get()?.docId
    },
    isContactor() {
      const { role } = this.$user.get()
      return role.contractor
    },
    today() {
      return this.formatDate(new Date(), false)
    },
    title() {
      const { start, end } = this
      if (!start || !end) {
        return ''
      }

      const startMonth = this.monthFormatter(start)
      const startYear = start.year
      const startDay = start.day + this.nth(start.day)

      /* eslint-disable */
      switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`
        case 'week':
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`
      }
      /* eslint-enable */
      return ''
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC',
        month: 'long',
      })
    },
    cancelEventDisabled() {
      // if client
      // cancellable if > 24h
      const now = new Date()
      const cancelDateLimit = new Date(this.selectedEvent?.start) // get fullDate
      cancelDateLimit.setDate(cancelDateLimit.getDate() - 1) // set the limit 24h before
      const res = cancelDateLimit > now
      const status = this.selectedEvent?.status === 'cancel' // even cancelled
      return !res || status || !this.cancelReasonIsSet
    },
    cancelReasonIsSet() {
      return !!this.cancelReason.value
    },
  },
  mounted() {
    this.$refs.calendar.checkChange()
  },
  watch: {
    selectedOpen(val) {
      // need to reset cancelReason as on object when the dialog closed
      if (!val) {
        this.cancelReason = {}
      }
    },
  },
  methods: {
    getTypeToLabel(label) {
      return this.$t(`calendar.${label.toLowerCase()}`)
    },
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    getEventTextColor(event) {
      return event.status === 'pending' ? 'black' : 'white'
    },
    setToday() {
      this.focus = this.today
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => (this.selectedOpen = true), 10) // eslint-disable-line
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange({ start, end }) {
      this.$emit('change', { start })
      this.start = start
      this.end = end
    },
    nth(d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
    },
    async cancelEvent(item) {
      await this.$store.dispatch('entities/update', {
        name: 'event-update',
        type: 'events',
        docId: item.docId,
        data: {
          color: 'red darken-2',
          status: 'cancel', // pending, fulfilled, rejected, cancel
          cancelReason: this.cancelReason,
        },
      })

      this.selectedOpen = false

      item.date = this.$d(new Date(item.start), 'digits')
      this.sendCancelEmail(item)

      // Record custom event to analytics "cancelEvent"
      logEvent('cancelEvent', {reason: this.cancelReason})
    },
    async sendCancelEmail(payload) {
      // todo replace by store action
      payload.date = this.$d(new Date(payload.start), 'digits')
      try {
        this.$store.dispatch('mail/cancelEvent', { payload })
      } catch (e) {
        console.log('sendCancelEmail error', e)
        const err = new Error('[Calendar] sendCancelEmail', e)
        window.newrelic.noticeError(err)
      }
    },
    onChangeCancelReason(value) {
      this.cancelReason = value
    },
  },
}
</script>

<style></style>
