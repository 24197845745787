<template>
  <div>
    <v-card>
      <v-card-title>
        <v-select
          :items="items"
          :label="$t('eventsList.filterByMonth')"
          @change="onChange"
          hide-details
        ></v-select>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('global.search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <VDataTable
        :headers="headers"
        :items="filteredEvents"
        :search="search"
        :items-per-page="10"
        :sort-by="['name', 'user', 'organization', 'date', 'type', 'status']"
        class="elevation-1"
      >
        <template v-slot:item.status="{ item }">
          <v-chip :color="item.color" dark>{{ item.status }}</v-chip>
        </template>

        <template v-slot:item.start="{ item }">
          {{ $d(new Date(item.start), 'digits') }}
        </template>

        <template v-slot:item.actions="{ item }">
          <EventActions :event="item" :baseEvents="baseEvents" />
        </template>
      </VDataTable>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { VDataTable, VCard, VCardTitle, VSpacer, VTextField } from 'vuetify/lib'

import EventActions from '@/components/EventActions'

const date = new Date()

export default {
  name: 'EventsList',
  components: {
    VDataTable,
    VCard,
    VCardTitle,
    VSpacer,
    VTextField,
    EventActions,
  },
  data() {
    return {
      dialog: false,
      docId: null,
      search: '',
      headers: [
        {
          text: this.$t('eventsList.headline.course'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: this.$t('eventsList.headline.customer'), value: 'user.email' },
        { text: this.$t('eventsList.headline.date'), value: 'start' },
        { text: this.$t('eventsList.headline.organization'), value: 'organization.name' },
        { text: this.$t('eventsList.headline.validByClient'), value: 'validByClient' },
        { text: this.$t('eventsList.headline.formationType'), value: 'type' },
        { text: this.$t('eventsList.headline.cost'), value: 'cost' },
        { text: this.$t('eventsList.headline.status'), value: 'status' },
        { text: this.$t('eventsList.headline.actions'), value: 'actions', sortable: false },
      ],
      items: [
        {
          text: this.$t('global.all'),
          value: 'all',
        },
        {
          text: this.$d(date.setMonth(0), 'month_long'),
          value: 1,
        },
        {
          text: this.$d(date.setMonth(1), 'month_long'),
          value: 2,
        },
        {
          text: this.$d(date.setMonth(2), 'month_long'),
          value: 3,
        },
        {
          text: this.$d(date.setMonth(3), 'month_long'),
          value: 4,
        },
        {
          text: this.$d(date.setMonth(4), 'month_long'),
          value: 5,
        },
        {
          text: this.$d(date.setMonth(5), 'month_long'),
          value: 6,
        },
        {
          text: this.$d(date.setMonth(6), 'month_long'),
          value: 7,
        },
        {
          text: this.$d(date.setMonth(7), 'month_long'),
          value: 8,
        },
        {
          text: this.$d(date.setMonth(8), 'month_long'),
          value: 9,
        },
        {
          text: this.$d(date.setMonth(9), 'month_long'),
          value: 10,
        },
        {
          text: this.$d(date.setMonth(10), 'month_long'),
          value: 11,
        },
        {
          text: this.$d(date.setMonth(11), 'month_long'),
          value: 12,
        },
      ],
      filteredValue: 'all',
    }
  },
  computed: {
    events() {
      return this.$store.getters['entities/list']({
        type: 'events',
        filter: (item) => item.status !== 'fulfilled' && item.status !== 'delete',
      })
    },
    filteredEvents() {
      return this.events.filter((item) => {
        if (this.filteredValue === 'all') {
          return item
        }
        return item.date.month === this.filteredValue
      })
    },
    baseEvents() {
      return (
        this.$store.getters['entities/get']({
          key: this.contractorId,
          type: 'baseEvents',
        }) || {}
      )
    },
    contractorId() {
      return this.$user.get()?.docId
    },
  },
  watch: {
    contractorId: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.eventsFetch()
        }
      },
      immediate: true,
    },
  },
  created() {
    this.eventsFetch()
  },
  methods: {
    onChange(value) {
      this.filteredValue = value
    },
    async eventsFetch() {
      if (!this.contractorId) return

      // load baseEvents
      await this.$store.dispatch('entities/get', {
        name: 'base-events-get',
        type: 'baseEvents',
        docId: this.contractorId,
      })

      await this.$store.dispatch('entities/list', {
        name: 'events-get-pending',
        type: 'events',
        queries: {
          where: [
            {
              key: 'contractor.docId',
              operator: '==',
              value: this.contractorId,
            },
            {
              key: 'status',
              operator: '==',
              value: 'pending',
            },
          ],
        },
      })

      await this.$store.dispatch('entities/list', {
        name: 'events-get-cancel',
        type: 'events',
        queries: {
          where: [
            {
              key: 'contractor.docId',
              operator: '==',
              value: this.contractorId,
            },
            {
              key: 'status',
              operator: '==',
              value: 'cancel',
            },
          ],
        },
      })
    },
  },
}
</script>

<style lang="scss" module></style>
