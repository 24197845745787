const EventsProvider = {
  props: {
    organizationId: {
      type: String,
    },
  },
  created() {
    this.loadData()
  },
  watch: {
    organizationId(val, oldval) {
      if (val !== oldval) {
        this.loadData()
      }
    },
  },
  computed: {
    data() {
      const users = this.$store.getters['entities/list']({
        type: 'users',
        filter: (item) => item.organization.docId === this.organizationId,
      })

      return users
    },
    loading() {
      return this.$store.getters['requests/loading']({
        name: 'users-get',
      })
    },
  },
  methods: {
    loadData() {
      return this.$store.dispatch('entities/list', {
        name: `users-get-${this.organizationId}`,
        type: 'users',
        queries: {
          where: [
            {
              key: 'organization.docId',
              operator: '==',
              value: this.organizationId,
            },
          ],
        },
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      loading: this.loading,
      data: this.data,
    })
  },
}

export default EventsProvider
