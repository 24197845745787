<template>
  <VCard
    class="mx-auto mt-8"
    max-width="1200px"
    outlined
  >
    <VCardTitle>{{ $t('usersList.pageTitle') }}</VCardTitle>
    <VCardText>
      <UsersProvider>
        <div slot-scope="{ data, loading }">
          <div v-if="loading">{{$t('global.loading')}}</div>

          <div v-else>
            <Users :users="data"/>
          </div>
        </div>
      </UsersProvider>

    </VCardText>
  </VCard>
</template>

<script>
import { VCard, VCardTitle, VCardText } from 'vuetify/lib'
import UsersProvider from '@/modules/Users/UsersProvider'
import Users from '@/modules/Users/Users.vue'
import {logEvent} from '@/utils/logEvent'

export default {
  name: 'UsersList',
  components: {
    VCard,
    VCardTitle,
    VCardText,
    UsersProvider,
    Users,
  },
  props: {
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - UsersList',
  },
  data: () => ({

  }),
  computed: {
  },
  created() {
    // Record custom event to analytics "usersListPage"
    logEvent('usersListPage')
  },
  methods: {
  },
}
</script>

<style lang="scss" module>

</style>
