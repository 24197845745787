<template>
  <div :class="$style.wrapper">
    <div :class="$style.logo">FreeDay</div>
    <VCard class="mx-auto" max-width="360" outlined>
      <VCardTitle>Se connecter</VCardTitle>

      <VCardText>
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <VForm>
            <ValidationProvider v-slot="v" rules="required|email" name="Email">
              <v-text-field
                v-model="email"
                :label="$t('global.email') + '*'"
                outlined
                required
                :error-messages="v.errors[0]"
              />
            </ValidationProvider>

            <ValidationProvider v-slot="v" rules="required|min:6|password" name="Password">
              <v-text-field
                v-model="password"
                type="password"
                :label="$t('login.password') + '*'"
                outlined
                required
                :hint="$t('login.hints.pwd')"
                persistent-hint
                :error-messages="v.errors[0]"
              />
            </ValidationProvider>

            <div>
              <vue-recaptcha
                v-if="sitekey"
                :loadRecaptchaScript="true"
                ref="recaptcha"
                @verify="onVerify"
                @expired="onExpired"
                :sitekey="sitekey"
              >
              </vue-recaptcha>
              <!-- <button @click="resetRecaptcha"> Reset ReCAPTCHA </button> -->
            </div>

            <v-btn
              :disabled="invalid || !recaptcha"
              color="success"
              @click="login"
              class="mr-2 my-4"
            >
              {{ $t('login.signin') }}
            </v-btn>

            <v-btn
              :disabled="invalid || !recaptcha"
              color="info"
              @click="createAccount"
              class="mr-2 my-4"
              :loading="loading"
            >
              {{ $t('login.signup') }}
            </v-btn>
          </VForm>
          <div class="text-center">
            <router-link :to="{ name: 'forgotten' }">
              {{ $t('login.forgottenpwd') }}
            </router-link>
          </div>
        </ValidationObserver>
      </VCardText>
    </VCard>
  </div>
</template>

<script>
import 'firebase/functions'
import { db } from '@/firebase'
import { logEvent } from '@/utils/logEvent'
import { signIn, signUp } from '@/firebase/authenticate'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VCard, VCardTitle, VCardText, VForm, VTextField, VBtn } from 'vuetify/lib'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'LoginComponent',
  components: {
    VCard,
    VCardTitle,
    VCardText,
    VForm,
    VTextField,
    VBtn,
    ValidationProvider,
    ValidationObserver,
    'vue-recaptcha': VueRecaptcha,
  },
  data() {
    return {
      email: null,
      password: null,
      loading: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY || '',
      recaptcha: process.env.VUE_APP_RECAPTCHA_DISABLED || false,
    }
  },
  methods: {
    // Recaptcha methods
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute()
    },
    onVerify(/* response */) {
      this.recaptcha = true
    },
    onExpired() {
      this.recaptcha = false
    },
    // resetRecaptcha() {
    //   this.$refs.recaptcha.reset() // Direct call reset method
    // },
    async login() {
      try {
        await signIn({ email: this.email, password: this.password })

        // Record custom event to analytics "Login"
        logEvent('login', {
          method: 'email',
        })

        this.goto()
      } catch (e) {
        console.error('login error', e)
        this.$refs.form.setErrors({
          Email: [e.message],
        })

        const err = new Error(`[Login] sigin: ${e.code}`)
        window.newrelic.noticeError(err, { freeday: e })
      }
    },
    async createAccount() {
      this.loading = true
      try {
        const user = await signUp({ email: this.email, password: this.password })
        // console.log('user created', user)

        // save new user datas into users collection
        const { uid, email, displayName, metadata, phoneNumber, photoURL } = user

        const data = {
          docId: uid,
          createdAt: metadata.creationTime,
          organization: {
            name: '',
            docId: '',
          },
          person: {
            email,
            displayName,
            firstName: '',
            lastName: '',
            phoneNumber,
            photoURL,
          },
          role: {
            customer: true,
          },
        }

        // create user into users collection
        await db.collection('users').doc(uid).set(data)

        // Record custom event to analytics "CreateAccount"
        logEvent('createAccount', {
          docId: uid,
        })

        // temp for let user document creation
        setTimeout(() => {
          this.goto()
        }, 2000)
      } catch (e) {
        // manualy trigger message erreur into veeValidate
        this.$refs.form.setErrors({
          Email: [e.message],
        })

        this.loading = false

        const err = new Error(`[Login] createAccount: ${e.code}`)
        window.newrelic.noticeError(err, { freeday: e })
      }
    },
    async goto() {
      this.$router.push({ name: 'home' })
    },
  },
}
</script>

<style lang="scss" module>
.logo {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}
</style>
