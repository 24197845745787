<template>
  <VCard
    class="mx-auto mt-8"
    max-width="1200px"
    outlined
  >
    <VCardTitle>{{ $t('notifications.pageTitle') }}</VCardTitle>
    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="title"
              :label="$t('notifications.title')"
              required
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
          >
            <v-textarea
              filled
              :label="$t('notifications.message')"
              :value="message"
              @change="onChange"
            ></v-textarea>
          </v-col>

          <v-col cols="12">
            <v-btn @click="notif">{{ $t('global.send') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-form>

  </VCard>
</template>

<script>
import {
  VBtn, VRow, VCol, VTextField, VCard,
} from 'vuetify'
import { functions } from '@/firebase'

export default {
  name: 'NotificationsComponent',
  components: {
    VBtn, VRow, VCol, VTextField, VCard,
  },
  props: {
  },
  metaInfo: {
    title: 'Freeday',
    titleTemplate: '%s - Notifications',
  },
  data: () => ({
    medias: [],
    title: null,
    message: null,
    valid: null,
  }),
  computed: {
  },
  created() {

  },
  methods: {
    onChange(event) {
      this.message = event
    },
    notif() {
      const payload = {
        data: {
          title: this.title || 'test',
          body: this.message || 'test',
          // icon: 'https://firebasestorage.googleapis.com/v0/b/stevenexavier-dev.appspot.com/o/images%2Fbulma.jpg?alt=media&token=0d45ce52-7179-49c5-b6e0-ab0ea9540a65',
        },
      }
      // onCall
      const notificationsOnCall = functions.httpsCallable('notificationsOnCall')
      notificationsOnCall(payload).then((res) => console.log('notificationsOnCall', res))
    },
  },
}
</script>

<style lang="scss" module>
.media {
  width: 250px;
  height: auto;
}
</style>
