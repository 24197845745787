<template>
  <div>
    <EventsList/>

    <div class="d-flex justify-center align-center mt-8">
      <v-btn
        dark
        color="primary"
        @click="dialog = true"
      >
        Mes dispositions
      </v-btn>
    </div>

    <Calendar/>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Admin</v-toolbar-title>
      </v-toolbar>

      <v-card>
        <v-card-title class="headline">Gérer mes indisponiblilité</v-card-title>

        <v-card-text class="d-flex justify-center">
          <DatePickerContractor
            :contractorId="contractorId"
            @close='dialog = false'
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Calendar from '@/views/Calendar.vue'
import EventsList from '@/views/EventsList.vue'
import DatePickerContractor from '@/modules/Calendar/components/DatePickerContractor.vue'

import {
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VIcon,
  VBtn,
  VToolbar,
} from 'vuetify/lib'

export default {
  name: 'HomeContractor',
  components: {
    Calendar,
    EventsList,
    DatePickerContractor,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VIcon,
    VBtn,
    VToolbar,
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    contractorId() {
      return this.$user.get()?.docId
    },
  },
}
</script>

<style lang="scss" module>
</style>
