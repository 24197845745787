import { auth } from '@/firebase'

// console.log('firebase auth', auth)

/**
 *
 * @param {*} param0
 * auth.onAuthStateChanged((user) => {
      console.log('user', { user, uid: user.uid })
    })
 */

// manage USER
// https://firebase.google.com/docs/auth/web/manage-users
// update Profile
// set New password
// set new email
//
const currentUser = () => auth.currentUser

const currentUserUpdateProfile = (props) => {
  const { displayName, photoURL, phoneNumber } = props
  return auth.currentUser.updateProfile({
    displayName,
    photoURL,
    phoneNumber,
  })
}

// login
const signIn = (props) => {
  const { email, password } = props
  return auth.signInWithEmailAndPassword(email, password)
    .then((cred) => {
      console.log('🚪 signIn')
      // console.log({ user: cred.user, uid: cred.user.uid })
      // console.log('currentUser', currentUser())
      return cred.user
    })
}

// create Account
const signUp = (props) => {
  const { email, password } = props
  // .setCustomUserClaims(uid, {admin: true})
  return auth.createUserWithEmailAndPassword(email, password)
    .then((cred) => {
      console.log('🚪 signUp')
      // console.log({ user: cred.user, uid: cred.user.uid })

      return cred.user
    })
}

// logout
const signOut = () => auth.signOut()
  .then(() => console.log('🏃‍♂️ signOut'))

export {
  signIn, signUp, signOut, currentUser, currentUserUpdateProfile,
}
