import { render, staticRenderFns } from "./App.vue?vue&type=template&id=f33d090c"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=f33d090c&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.91.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports