<template>
  <div>
    <v-date-picker
      v-model="dates"
      multiple
      no-title
      :events="arrayEvents"
      :event-color="colorsEvents"
      :allowed-dates="allowedDates"
      :first-day-of-week="1"
      locale="fr"
      color="primary"
    >
    </v-date-picker>

    <div class='text-center mt-8'>
      <v-btn
        color="primary"
        :disabled="disabled"
        @click="onSubmit"
      >
        {{ $t('global.valid') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  // VTextField,
  // VTextarea,
  // VAutocomplete,
  VDatePicker,
  VBtn,
} from 'vuetify/lib'

import colors from '@/utils/colors'

export default {
  name: 'DatePickerContractor',
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    contractorId: {
      type: String,
      required: true,
    },
  },
  components: {
    VDatePicker,
    // VTextField,
    // VTextarea,
    // VAutocomplete,
    VBtn,
  },
  data() {
    return {
      picker: new Date().toISOString().substr(0, 10),
      menuMultiple: false,
      dates: [],
      eventName: '',
      comment: '',
      cost: 0,
      type: 'non disponible',
    }
  },
  computed: {
    contractor() {
      return this.$store.getters['entities/get']({
        type: 'users',
        key: this.contractorId,
      })
    },
    disabled() {
      return (!this.contractorId || this.dates.length === 0)
    },
    colorsEvents() {
      return (date) => {
        const result = this.events.reduce((acc, cur) => {
          if (cur.contractor.docId === this.contractorId && cur.start.substr(0, 10) === date) {
            acc.push(cur.color)
          }
          return acc
        }, [])
        return result
      }
    },
    arrayEvents() {
      const result = this.events.reduce((acc, cur) => {
        if (cur.contractor.docId === this.contractorId && cur.status === 'fulfilled') {
          acc.push(cur.start.substr(0, 10))
        }
        return acc
      }, [])

      return result
    },
    baseEvents() {
      return this.$store.getters['entities/get']({
        key: this.contractorId,
        type: 'baseEvents',
      }) || {}
    },
  },
  created() {
    this.init()
  },
  warch: {
    contractorId(val, oldval) {
      if (val !== oldval) {
        this.init()
      }
    },
  },
  methods: {
    async init() {
      const where = [
        {
          key: 'contractor.docId',
          operator: '==',
          value: this.contractorId,
        },
      ]

      // fetch baseEvents
      await this.$store.dispatch('entities/get', {
        name: `fetch-base-events-${this.contractorId}`,
        type: 'baseEvents',
        docId: this.contractorId,
        queries: {
          where,
        },
      })
    },
    allowedDates(val) {
      // wait for baseEvents load datas
      // if (this.baseEvents.dates === undefined) return false

      // disable weekend date / firstDay and event day
      const firstDay = (new Date(val) > new Date(this.picker))
      const weekendDay = ![0, 6].includes(new Date(val).getDay())

      // return false if event status fulfilled exist
      const eventDay = !this.arrayEvents.includes(val)

      // return false if val exist into baseEvents dates
      const notAlloyDay = (this.baseEvents.dates === undefined) || (!this.baseEvents.dates.includes(val)) // eslint-disable-line
      return firstDay && weekendDay && eventDay && notAlloyDay
    },
    async onSubmit() {
      this.dates.forEach((date) => {
        this.saveNewEvent(date)
      })

      return true
    },
    async saveNewEvent(date) {
      const { docId, person, organization } = this.$user.get()

      const d = new Date(date)

      const event = {
        date: {
          year: d.getFullYear(),
          month: d.getMonth() + 1, // january = 0
          day: d.getDate(),
        },
        name: 'indisponible',
        start: date,
        end: date,
        status: 'pending',
        type: this.type,
        color: colors.notAvailable,
        user: {
          docId,
          email: person.email,
          fullName: (person.firstName || person.lastName)
            ? `${person.firstName} ${person.lastName}`
            : person.email,
        },
        organization,
        contractor: {
          docId: this.contractorId,
          email: this.contractor?.person.email,
          fullName: (this.contractor?.person.firstName || this.contractor?.person.lastName)
            ? `${this.contractor.person.firstName} ${this.contractor.person.lastName}`
            : this.contractor.person.email,
        },
        cost: this.cost,
        comment: this.comment,
      }

      console.log('event', event)

      await this.$store.dispatch('entities/set', {
        name: `new-event-${date}`,
        // type: 'events-multiple',
        type: 'events',
        data: event,
      })

      // this.sendEmail(event)
      this.dates = []
      this.type = null
      this.$emit('close')
    },
    // async sendEmail(event) {
    //   event.date = this.$d(new Date(event.start), 'digits')
    //   // todo replace by store action
    //   try {
    //     // console.log('send email', event)
    //     this.$store.dispatch('mail/newEvent', { event })
    //   } catch (e) {
    //     console.log('sendEmail error', e)
    //   }
    // },
  },
}
</script>
